<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<PeopleDetail></PeopleDetail>

	2023-01-30	init

-->

<template>
	<article class="PeopleDetail contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<!--
			<strong>{{$options.name}}</strong><br/>

			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		-->

		<div class="PeopleDetail__inner" :class="elmInnerClasses">
			<div class="PeopleDetail__grid">
				<div class="PeopleDetail__bigImageWrapper">
					<MhImage class="PeopleDetail__bigImage"
						:imageObject="bigImage"
						:mode="'image'"
					></MhImage>
				</div>
				<div class="PeopleDetail__quoteWrapper">
					<Spacer></Spacer>
					<h2 class="PeopleDetail__quote font font--grow-bd text text--align-center" v-html="quote"></h2>
					<Spacer></Spacer>
				</div>
				<div class="PeopleDetail__addressText font font--system85-medium" v-html="addressText"></div>
				<div class="PeopleDetail__contactText font font--system85-medium">
					<div v-html="contactText"></div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import Spacer from '@/components/contentModules/Spacer.vue'

	export default {
		name: 'PeopleDetail',
		components: {
			MhImage,
			Spacer,
		},
		mixins: [],
		props: {
			bigImage: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			quote: {
				type     : [String],
				default  : '',
				required : false,
			},
			addressText: {
				type     : [String],
				default  : '',
				required : false,
			},
			contactText: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.PeopleDetail { // debug
		[showBorders3] & {
			&__grid { background-color: fade( red, 25 ); }
			&__grid > * { background-color: fade( red, 25 ); }
		}
	}
	.PeopleDetail { // vars
	}
	.PeopleDetail { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&__grid {
			display: grid;
			gap: var( --app-grid-gap );
			row-gap: 0; // wird mit Spacer gelöst
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );

			@media @mq[dt] {
				grid-template-areas:
					"a a a a a a a a a a a a"
					". . b b b b b b b b . ."
					". . . . . . . . c c d d"
				;
			}
			@media @mq[md] {
				grid-template-areas:
					"a a a a a a a a"
					". b b b b b b ."
					". . . . c c d d"
				;
			}
			@media @mq[sm] {
				grid-template-areas:
					"a a a a a a a a"
					"b b b b b b b b"
					"c c c c d d d d"
				;
			}
		}
		&__bigImageWrapper { grid-area: a; }
		&__quoteWrapper { grid-area: b; }
		&__addressText { grid-area: c; }
		&__contactText { grid-area: d; display: flex; flex-direction: column; justify-content: flex-end; }
		//&__contactText { background-color: green; }
		//&__contactText div { background-color: yellow; }
	}
	.PeopleDetail { // styling
		&__quote {
			color: var( --color-weiss );

			@media @mq[dt] { font-size: 50px; line-height: 50px; }
			@media @mq[md] { font-size: 34px; line-height: 34px; }
			@media @mq[sm] { font-size: 24px; line-height: 24px; }
		}
		&__addressText, &__contactText {
			@media @mq[dt] { font-size: 16px; line-height: 18px; }
			@media @mq[md] { font-size: 16px; line-height: 18px; }
			@media @mq[sm] { font-size: 12px; line-height: 14px; }
		}
		&__addressText, &__contactText {
			a { color: inherit; text-decoration: underline; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
