import Vue from 'vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import _ from 'lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueMatomo from 'vue-matomo'
//import VueResizeObserver from "vue-resize-observer"
import EventBus from '@/helper/EventBus.js'
import currentEnv from '@/helper/getCurrentEnv.js'
import router from '@/config/router.multilingual.js'
import store from '@/store/store.js'
import mqBreakpoints from '@/config/mqBreakpoints'
import App from '@/App.vue'
import MhPre from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhPre/v1/MhPre.plugin.js'

Vue.config.productionTip = false

// on error + warn: play beep sound and show errorBadge
// see: https://vuejs.org/v2/api/#errorHandler
// see: https://vuejs.org/v2/api/#warnHandler
const warnAndErrorHandler = ( err, vm, info ) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.

	// TODO: Perform any custom logic or log to server
	const componentName = _.get( vm, '$options.name' )

	// remove eventually previously added errorBadge
	const prevErrorBadge = document.body.querySelector('errorBadge')
	if (prevErrorBadge) document.body.removeChild(prevErrorBadge)
	// Show red dot as visible error indicator
	const badgeHeight = '150px'
	const errorBadgeElm = document.createElement('div')
	errorBadgeElm.classList.add("errorBadge")
	errorBadgeElm.style.cssText = `
		position: fixed;
		border-radius: 50%;
		top: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		left: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		width: ${badgeHeight};
		height: ${badgeHeight};
		opacity: 0.85;
		z-index: 100000;
		background: red;
	`;
	document.body.appendChild(errorBadgeElm);

	console.group('%cerrorHandler() •', 'color : red', componentName)
	console.error('err:', err)
	console.log('%cvm:', 'color : red', vm)
	console.log('%cinfo:', 'color : red', info)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(10, 320, 150)
}
if( ['local', 'stage'].includes( currentEnv.name ) ){
	Vue.config.warnHandler = warnAndErrorHandler
	Vue.config.errorHandler = warnAndErrorHandler
}

Vue.use( VueI18n )
Vue.use( VueAxios, axios )
Vue.use( VueLodash, { lodash: _ } )
Vue.use( VShowSlide)
// Vue.use( VueResizeObserver )
Vue.use( VueMq, mqBreakpoints )
Vue.use( MhPre )

// setup i18n
// see: http://kazupon.github.io/vue-i18n/started.html
const i18n = new VueI18n({
	locale: 'de', // set default locale
	fallbackLocale: 'en',
	messages: {
		de: require('./config/i18n.de-en').default.de,
		en: require('./config/i18n.de-en').default.en,
	},
})
// enable hot updates for i18n
// see: http://kazupon.github.io/vue-i18n/guide/hot-reload.html
if( module.hot ){
	module.hot.accept([ './config/i18n.de-en' ], () => {
		i18n.setLocaleMessage('de', require('./config/i18n.de-en').default.de)
		i18n.setLocaleMessage('en', require('./config/i18n.de-en').default.en)
	})
}

// setup vue-matomo
// see: https://github.com/AmazingDreams/vue-matomo
if (currentEnv.name == 'live') {
	Vue.use( VueMatomo, {
		// Configure your matomo server and site by providing
		host: 'https://matomo.tmo.wine',
		siteId: 1,

		// Changes the default .js and .php endpoint's filename
		// Default: 'matomo'
		trackerFileName: 'matomo',

		// Overrides the autogenerated tracker endpoint entirely
		// Default: undefined
		// trackerUrl: 'https://example.com/whatever/endpoint/you/have',

		// Overrides the autogenerated tracker script path entirely
		// Default: undefined
		// trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

		// Enables automatically registering pageviews on the router
		router: router,

		// Enables link tracking on regular links. Note that this won't
		// work for routing links (ie. internal Vue router links)
		// Default: true
		enableLinkTracking: true,

		// Require consent before sending tracking information to matomo
		// Default: false
		requireConsent: false,

		// Whether to track the initial page view
		// Default: true
		trackInitialView: true,

		// Run Matomo without cookies
		// Default: false
		disableCookies: true,

		// Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
		// Default: false
		enableHeartBeatTimer: false,

		// Set the heartbeat timer interval
		// Default: 15
		heartBeatTimerInterval: 15,

		// Whether or not to log debug information
		// Default: false
		debug: true,

		// UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
		// Default: undefined
		userId: undefined,

		// Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
		// Default: undefined, example '*.example.com'
		cookieDomain: undefined,

		// Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
		// Default: undefined, example: '*.example.com'
		domains: undefined,

		// A list of pre-initialization actions that run before matomo is loaded
		// Default: []
		// Example: [
		//   ['API_method_name', parameter_list],
		//   ['setCustomVariable','1','VisitorType','Member'],
		//   ['appendToTrackingUrl', 'new_visit=1'],
		//   etc.
		// ]
		preInitActions: []
	})
}
else if (currentEnv.name == 'stage') {
	Vue.use( VueMatomo, {
		// Configure your matomo server and site by providing
		host: 'https://matomo.tmo.wine',
		siteId: 2,

		// Changes the default .js and .php endpoint's filename
		// Default: 'matomo'
		trackerFileName: 'matomo',

		// Overrides the autogenerated tracker endpoint entirely
		// Default: undefined
		// trackerUrl: 'https://example.com/whatever/endpoint/you/have',

		// Overrides the autogenerated tracker script path entirely
		// Default: undefined
		// trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

		// Enables automatically registering pageviews on the router
		router: router,

		// Enables link tracking on regular links. Note that this won't
		// work for routing links (ie. internal Vue router links)
		// Default: true
		enableLinkTracking: true,

		// Require consent before sending tracking information to matomo
		// Default: false
		requireConsent: false,

		// Whether to track the initial page view
		// Default: true
		trackInitialView: true,

		// Run Matomo without cookies
		// Default: false
		disableCookies: true,

		// Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
		// Default: false
		enableHeartBeatTimer: false,

		// Set the heartbeat timer interval
		// Default: 15
		heartBeatTimerInterval: 15,

		// Whether or not to log debug information
		// Default: false
		debug: true,

		// UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
		// Default: undefined
		userId: undefined,

		// Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
		// Default: undefined, example '*.example.com'
		cookieDomain: undefined,

		// Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
		// Default: undefined, example: '*.example.com'
		domains: undefined,

		// A list of pre-initialization actions that run before matomo is loaded
		// Default: []
		// Example: [
		//   ['API_method_name', parameter_list],
		//   ['setCustomVariable','1','VisitorType','Member'],
		//   ['appendToTrackingUrl', 'new_visit=1'],
		//   etc.
		// ]
		preInitActions: []
	})
}

// setup and mount root
new Vue({
	i18n,
	router,
	store,
	data() {
		return {
			currentEnv: currentEnv,
			mqBreakpoints: mqBreakpoints,
		}
	},
	watch: {
		'$route.params.lang'(to, from) {	// set this.$i18n.local
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			this.$i18n.locale = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		}
	},
	computed: {
		isBelowDt() {
			const validMqs = [ 'xs', 'sm', 'md' ]

			return validMqs.includes(this.$mq)
		},
		isBelowMd() {
			const validMqs = [ 'xs', 'sm' ]

			return validMqs.includes(this.$mq)
		},
	},
	methods: {
		getPathFromUrl(url) {
			if (!url) return

			return url.replace(/^.*\/\/[^\/]+/, '')
		},
	},
	created() {
		//console.log('currentEnv:', currentEnv.name)

		// Set $i18n.locale from current route param
		// if router lang is not valid redirect to fallbackLang en
		this.$router.onReady((callback, errorCallback, doLog = false) => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)
			const fallbackLang = this.$i18n.fallbackLocale

			if (doLog) {
				console.group('main.js • $router.onReady()')
				console.log('routeLang:', routeLang)
				console.log('routeLangIsValid:', routeLangIsValid)
			}

			// set lang to en if router lang isnt valid
			if (!routeLangIsValid) {
				//const newPath = '/' + fallbackLang + '/' + to.fullPath.replace( '//', '/' )

				if (doLog) {
					console.log('router lang isnt valid')
					console.log('>>> set lang to:', fallbackLang)
				}

				//this.$router.replace( newPath )
				this.$i18n.locale = fallbackLang
			}
			// set locale if router lang is valid
			else {
				this.$i18n.locale = routeLangIsValid ? routeLang : fallbackLang
			}

			if (doLog) {
				console.groupEnd()
			}
		})
		/*
		*/

		// Set html lang attribute
		// 1)	on load
		const htmlElm = document.querySelector('html')
		this.$router.onReady(() => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			//console.log('set lang to:', htmlElm)
			htmlElm.lang = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		})
		// 2)	on route change
		this.$router.afterEach((to, from) => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			//console.log('set lang to:', htmlElm)
			htmlElm.lang = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		})
		/*
		*/
	},
	mounted() {

	},
	render: h => h(App)
}).$mount('#app')
