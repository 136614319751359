var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MhPre__collection",class:'MhPre__collection--nestedLevel-' + _vm.nestedLevel + ' ' +
		'MhPre__collection--type-' + ( _vm._.isArray( _vm.data ) ? 'array' : 'object' ),attrs:{"data-nestedLevel":_vm.nestedLevel,"XXXv-if":"_.size( data )"}},[(_vm.nestedLevel > 0)?_c('div',{staticClass:"MhPre__collectionInfo",class:'MhPre__collectionInfo--size-' + _vm._.size( _vm.data ),on:{"click":function($event){_vm.hasVisibleChilds = !_vm.hasVisibleChilds}}},[_c('a',{staticClass:"MhPre__foldBtn",class:{
				'MhPre__foldBtn--isActive' : _vm.hasVisibleChilds,
				'MhPre__foldBtn--isDisabled' : !_vm._.size( _vm.data ),
			}}),_c('span',{staticClass:"MhPre__info MhPre__info--brackets",domProps:{"innerHTML":_vm._s(_vm.bracketsStr)}}),_c('span',{staticClass:"MhPre__info MhPre__info--collectionSize",domProps:{"innerHTML":_vm._s(_vm.sizeStr)}})]):_vm._e(),(_vm._.size( _vm.data ))?_c('div',{directives:[{name:"show-slide",rawName:"v-show-slide",value:(_vm.nestedLevel == 0 || _vm.hasVisibleChilds),expression:"nestedLevel == 0 || hasVisibleChilds"}],staticClass:"MhPre__folder"},[(_vm._.size( _vm.data ) || 1==1)?_c('div',{staticClass:"MhPre__collectionItems",class:'MhPre__collectionItems--nestedLevel-' + _vm.nestedLevel + ' ' +
				'MhPre__collectionItems--size-' + _vm._.size( _vm.data )},_vm._l((_vm.data),function(v,k){return _c('div',{key:k,staticClass:"MhPre__collectionItem",class:'MhPre__collectionItem--nestedLevel-' + _vm.nestedLevel + ' ' +
					'MhPre__collectionItem--type-' + ( _vm._.isArray( _vm.data ) ? 'array' : 'object' )},[_c('div',{staticClass:"MhPre__collectionItemKey",class:'MhPre__collectionItemKey--nestedLevel-' + _vm.nestedLevel + ' ' +
					'MhPre__collectionItemKey--type-' + ( _vm._.isArray( _vm.data ) ? 'array' : 'object' )},[_c('span',[_vm._v(_vm._s(k))])]),_c('div',{staticClass:"MhPre__collectionItemValue",class:[
						'MhPre__collectionItemValue--nestedLevel-' + _vm.nestedLevel,
						'MhPre__collectionItemValue--type-' + ( _vm._.isArray( _vm.data ) ? 'array' : 'object' ),
						'MhPre__collectionItemValue--child-' + _vm.getChildComponentName( v ),
						'MhPre__collectionItemValue--isEmpty-' + (_vm._.isEmpty( v ) ? 'true' : 'false' ) ]},[_c('MhPre__' + _vm.getChildComponentName( v ),{tag:"div",attrs:{"data":v,"name":_vm.name,"nestedLevel":_vm.nestedLevel+1,"hasVisibleChilds":_vm.unfoldedCollections.includes( k + _vm.nestedLevel )}})],1)])}),0):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }