<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Card></Card>

	2023-01-25	init

-->

<template>
	<div class="Card" :class="elmClasses" :style="elmStyles">
		<div class="Card__ratioHelper"></div>
		<a class="Card__inner" :class="elmInnerClasses" :href="internalLink">
			<!-- overTitle -->
			<div class="Card__overTitle font font--system85-medium"
				:style="overTitleStyle"
				v-html="overTitle"
				v-if="overTitle"
			></div>
			<!-- image -->
			<div class="Card__left">
				<MhImage class="Card__image"
					XXXacfImagePost="image"
					:imageObject="image"
					:mode="['sm', 'xs'].includes( $mq ) ? 'img' : 'cover'"
				></MhImage>
				<div class="Card__imagePlaceholder" v-if="!image"></div>
			</div>
			<!-- text -->
			<div class="Card__right">
				<div class="Card__text">
					<div class="Card__headline font font--grow-bd text text--align-center" v-html="headline"></div>
					<div class="Card__subHeadline" v-html="subHeadline"></div>
				</div>

				<div class="Card__linkWrapper">
					<div class="Card__link">
						<span class="font font--system85-medium">{{linkText}}</span>
						<span class="Card__linkArrow font font--grow-bd">›</span>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	//import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v6 • vue 2+3/MhImage.vue'

	export default {
		name: 'Card',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			overTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			bgColor: {
				type     : [String],
				default  : 'blue',
				required : false,
			},
			image: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			headline: {
				type     : [String],
				default  : '',
				required : false,
			},
			subHeadline: {
				type     : [String],
				default  : '',
				required : false,
			},
			linkText: {
				type     : [String],
				default  : '',
				required : false,
			},
			internalLink: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			elmStyles(){
				let styles = {}

				styles.backgroundColor = this.bgColor

				return styles
			},
			elmClasses(){
				let classes = []

				if( this.overTitle ) classes.push( this.$options.name + '--hasOverTitle')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
			overTitleStyle(){
				let styles = {}

				styles.color = this.bgColor

				return styles
			}
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.Card { // debug
		[showBorders3] & {
			&__inner { background-color: fade( green, 15 ); }
			&__overTitle { background-color: fade( green, 15 ); }
			&__ratioHelper { background-color: fade( green, 15 ); }
			&__card { background-color: fade( green, 15 ); }

			&__left { background-color: fade( green, 15 ); }
			&__image { background-color: fade( green, 15 ); }

			&__right { background-color: fade( green, 15 ); }
			&__text { background-color: fade( green, 25 ); }
			&__headline { background-color: fade( green, 25 ); }
			&__subHeadline { background-color: fade( green, 25 ); }
			&__linkWrapper { background-color: fade( green, 50 ); }
		}
	}
	.Card { // vars
		--Card-padding: 12px;
	}
	.Card { // layout
		position: relative;

		&--hasOverTitle {
			margin-top: 1.25em;
		}

		&__inner {
			@media @mq[dt] {
				position: absolute; inset: 0;
				display: grid;
				gap: var( --app-grid-gap );
				grid-template-columns: repeat( 10, minmax(0, 1fr) );
			}
			@media @mq[md] {
				position: absolute; inset: 0;
				display: grid;
				gap: var( --app-grid-gap );
				grid-template-columns: repeat( 8, minmax(0, 1fr) );
			}
			@media @mq[sm] {
				position: relative;
				display: flex;
				gap: initial;
				flex-direction: column;
			}
		}
		&__ratioHelper {
			@media @mq[dt] { position: relative; padding-bottom: 45%; }
			@media @mq[md] { position: relative; padding-bottom: 45%; }
			@media @mq[sm] { display: none; }
		}

		&__overTitle {
			position: absolute;
			transform: translateY( -100% );
			padding-bottom: 0.5em;
		}
		&__left {
			position: relative;
			display: flex;
			overflow: hidden;
			padding: var(--Card-padding);

			@media @mq[dt] { grid-column: span 6; padding-right: 0; }
			@media @mq[md] { grid-column: span 5; padding-right: 0; }
			@media @mq[sm] { padding-right: var(--Card-padding); padding-bottom: 0; }
		}
		&__image, &__imagePlaceholder {
			flex-grow: 1;
		}
		.MhImage__imageWrapper--mode-cover { // needed for safaris padding-bottom AND position:absolute behavior
			padding-bottom: initial !important;
		}

		&__right {
			padding: var(--Card-padding);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media @mq[dt] { grid-column: span 4; }
			@media @mq[md] { grid-column: span 3; }
			@media @mq[sm] { padding-top: 0; }
		}
		&__text {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			flex-grow: 1;

			@media @mq[sm] { padding-top: 1.5em; }
		}
		&__headline {}
		&__subHeadline {
			padding-top: 0.75em;
			padding-bottom: 1em;
		}
		&__linkWrapper {
			display: flex;
			justify-content: flex-end;
		}
		&__link {
			display: flex;
			gap: 0.5em;
			flex-grow: 1;
			justify-content: flex-end;
			align-items: center;
		}
	}
	.Card { // styling
		color: white;
		cursor: pointer;

		&:hover &__link { opacity: 1; }

		&__inner { color: inherit; }

		&__overTitle {
			font-size: 16px; line-height: 1em;
		}
		&__imagePlaceholder { // placeholder rect if no image
			background-color: fade( white, 25 );
		}
		&__headline {
			@media @mq[dt] { font-size: 40px; line-height: 1em; }
			@media @mq[md] { font-size: 32px; line-height: 1em; }
			@media @mq[sm] { font-size: 24px; line-height: 1em; }
		}
		&__subHeadline {
			@media @mq[dt] { font-size: 16px; line-height: 1em; }
			@media @mq[md] { font-size: 16px; line-height: 1em; }
			@media @mq[sm] { font-size: 16px; line-height: 1em; }
		}
		&__link {
			color: inherit;
			transition: all 0.2s ease;
			opacity: 0.65;

			@media @mq[dt] { font-size: 16px; line-height: 1em; }
			@media @mq[md] { font-size: 16px; line-height: 1em; }
			@media @mq[sm] { font-size: 12px; line-height: 1em; }
		}
		&__linkArrow {
			@media @mq[dt] { font-size: 28px; line-height: 1em; }
			@media @mq[md] { font-size: 28px; line-height: 1em; }
			@media @mq[sm] { font-size: 28px; line-height: 1em; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
