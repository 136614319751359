<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Events></Events>

	2023-01-25	init

-->

<template>
	<div class="Events contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<!--
		<strong>{{$options.name}}</strong><br/>

		<MhPre :name="$options.name + '.$props'"
			:data="$props"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.$attrs'"
			:data="$attrs"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.posts'"
			:data="posts"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		-->

		<div class="Events__inner" :class="elmInnerClasses">
			<template v-if="!isFetching && !posts.length">
				<!--
				<FlowText :text="$t('messages.noUpcomingEvents')"></FlowText>
				-->
				<Headline :text="$t('messages.noUpcomingEvents')"></Headline>
			</template>
			<template v-else>
				<div class="Events__grid">
					<div class="Events__eventCard" v-for="(post, postIndex) in posts" :key="'s' + postIndex">
						<Spacer :type="'l'" v-if="postIndex > 0"></Spacer>
						<Card
							:bgColor="'var(--color-blau)'"
							:overTitle="''"
							:image="_.get( post, 'acf.image' )"
							:headline="_.get( post, 'title.rendered' )"
							:subHeadline="getDateTimeStr( post )"
							:linkText="'Mehr erfahren'"
							:internalLink="_.get( post, 'link' )"
						></Card>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import Card from '@/components/Card.vue'
	import Headline from '@/components/contentModules/Headline.vue'
	import FlowText from '@/components/contentModules/FlowText.vue'
	import Spacer from '@/components/contentModules/Spacer.vue'

	export default {
		name: 'Events',
		components: {
			Card,
			Headline,
			FlowText,
			Spacer,
		},
		mixins: [
			restHandler,
		],
		props: {},
		data(){
			return {
				posts : [],
				isFetching : false,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			getDateTimeStr( post ){
				// 15.7.2023 • 17<sup>30</sup>–22<sup>30</sup> h

				const date = this._.get( post, 'acf.startDate', '' )
				const startTime = this._.get( post, 'acf.startTime', '' )
				const endTime = this._.get( post, 'acf.endTime', '' )

				const year = date.substring( 0, 4 )
				const month = date.substring( 4, 6 )
				const day = date.substring( 6, 8 )

				const startTimeHours = startTime.substring( 0, 2 )
				const startTimeMinutes = startTime.substring( 2, 4 )
				const endTimeHours = startTime.substring( 0, 2 )
				const endTimeMinutes = startTime.substring( 0, 2 )

				let str = day + '.' + month + '.' + year +
					' &nbsp; ' + startTimeHours + '<sup>' + startTimeMinutes + '</sup>'

				if( endTime ){
					str += '–' + endTimeHours + '<sup>' + endTimeMinutes + '</sup>'
				}
				if( startTimeHours ){
					str += '&nbsp;h'
				}

				return str
			},
			fetchPosts(){
				const fetchParams = {
					range : 'nowAndFuture',
					lang : this.$i18n.locale,
					perPage: -1,
				}

				this.isFetching = true

				// fetch page
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/events',
					params : fetchParams,
					callback : (response) => {
						this.posts = response.data.result
						this.isFetching = false

						//runCallback()
					},
				})
			},
		},
		created(){},
		mounted(){
			this.fetchPosts()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.Events { // debug
		[showBorders2] & {}
	}
	.Events { // vars
	}
	.Events { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
		&__grid {
			display: grid;
			gap: var( --app-grid-gap );
			row-gap: 0;
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}

		&__eventCard {
			grid-column: span 10;

			&:nth-child(2n){ grid-column: 3 / span 10; }
			&:nth-child(3n){ grid-column: 2 / span 10; }
		}
	}
	.Events { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
