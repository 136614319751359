<template>
	<MhRouterView class="HomeView view" :isContentReady="isContentReady">
		<div class="view__inner">
			<div class="minHeight100vhMinusMainHeader">

				<!--
				<MhPre :name="'post'"
					:data="post"
					:isStyled="true"
					:maxHeight="'650px'"
					:keyColumnWidth="'9em'"
				></MhPre>
				-->

				<MhContentModuleWrapper
					v-for="(contentModule, contentModuleIndex) in _.get( post, 'acf.contentModules' )"
					:contentModuleType="contentModule.acf_fc_layout"
					:key="contentModuleIndex"
					v-bind="contentModule"
				></MhContentModuleWrapper>

				<Spacer></Spacer>
				<Spacer></Spacer>

			</div>

			<MainFooter
				:data="app.footerData"
			></MainFooter>
		</div>
	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v5/MhRouterView.vue'
	import MhContentModuleWrapper from '@/components/vendor/MhContentModuleWrapper/v1/MhContentModuleWrapper.vue'
	import Spacer from '@/components/contentModules/Spacer.vue'
	import MainFooter from '@/components/MainFooter.vue'

	export default {
		name: 'HomeView',
		components: {
			MhRouterView,
			MhContentModuleWrapper,
			Spacer,
			MainFooter,
		},
		mixins: [
			restHandler
		],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady: false,
				post: undefined,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch fontpage
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/frontpage',
					params : {
						lang : this.$i18n.locale,
					},
					callback : (response) => {
						this.post = response.data.result[0]

						runCallback()
					},
				})
			},
		},
		mounted() {
			this.fetchAll(()=>{
				this.isContentReady = true

				EventBus.$emit('registerTranslation', this._.get( this.post, 'polylang.translations' ) )
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.HomeView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
