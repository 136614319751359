<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LangSwitcher></LangSwitcher>

	2023-02-10	init

-->

<template>
	<div class="LangSwitcher font font--system85-medium" :class="elmClasses">
		<a class="LangSwitcher__btn"
			:class="currentLang === 'de' ? 'LangSwitcher__btn--isActive' : 'LangSwitcher__btn--isInactive'"
			:href="getTranslationLink('de')"
		>DE</a>
		<a class="LangSwitcher__btn"
			:class="currentLang === 'en' ? 'LangSwitcher__btn--isActive' : 'LangSwitcher__btn--isInactive'"
			:href="getTranslationLink('en')"
		>EN</a>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LangSwitcher',
		components: {},
		mixins: [],
		props: {},
		data(){
			return {
				translationUrls : undefined
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ){
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			currentLang(){
				return this.$i18n.locale
			},
		},
		methods: {
			getTranslationLink( lang ){
				const translatedUrl = this._.get( this.translationUrls, lang )
				return translatedUrl ? translatedUrl : '/' + lang + '/'
			},
			registerTranslation( args, doLog = false ){
				const currentLang = this.currentLang

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• registerTranslation( args )', args )
					console.log('args:', args)
					console.log('currentLang:', currentLang)
					console.groupEnd()
				}

				this.translationUrls = args
			},
		},
		created(){
			EventBus.$on('registerTranslation', this.registerTranslation )
		},
		mounted(){},
		destroyed(){
			EventBus.$off('registerTranslation', this.registerTranslation )
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.LangSwitcher { // debug
		[showBorders2] & {}
	}
	.LangSwitcher { // vars
	}
	.LangSwitcher { // layout
		display: flex;
		padding: 0 2em;

		&__btn {
			padding: 0 0.5em;
			margin-top: -0.2em; // fix vertical offset
		}
	}
	.LangSwitcher { // styling
		.MainNav & { color: white; }
		.App--routeName-EventView & { color: white; }
		.App--routeName-PeopleView & { color: white; }

		@media @mq[dt] { font-size: 16px; line-height: 1em; }
		@media @mq[md] { font-size: 12px; line-height: 1em; }
		@media @mq[sm] { font-size: 12px; line-height: 1em; }

		&__btn {
			transition: all 0.1s ease;
			color: inherit;
			padding: 0 0.5em;
		}
		&__btn--isInactive {
			opacity: 0.35;
		}
		&__btn:hover {
			opacity: 1;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
