<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MainHeader></MainHeader>

	2023-01-29	init

-->

<template>
	<nav class="MainHeader" :class="elmClasses">
		<div class="MainHeader__inner">
			<div class="MainHeader__row">
				<button class="menuToggleBtn menuToggleBtn--isInactive"
					@click="$emit('openMainNav')">
					<span class="menuToggleBtnLine"></span>
					<span class="menuToggleBtnLine"></span>
					<span class="menuToggleBtnLine"></span>
				</button>
				<LangSwitcher class="MainHeader__langSwitcher"
					v-if="_.get( app, 'acfOptions.hasVisibleLangSwitcher' )"
				></LangSwitcher>
				<div class="expander"></div>
				<a class="MainHeader__logo" :href="homeUrl">Home</a>
			</div>
		</div>
	</nav>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import LangSwitcher from '@/components/LangSwitcher.vue'

	export default {
		name: 'MainHeader',
		components: {
			LangSwitcher,
		},
		mixins: [],
		props: {},
		data(){
			return {}
		},
		watch: {},
		computed: {
			homeUrl(){
				const url = window.location.protocol + '//' + window.location.host + '/' + this.$i18n.locale + '/'

				return url
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MainHeader { // debug
		[showBorders2] & {
			.outline( red );
			.label( "MainHeader", red, white );
		}
		[showBorders3] & {
			&__row * {
				background-color: fade( red, 25 );
				outline: 1px solid fade( red, 25 );
			}
			/*
			&__menuToggleBtn { background-color: fade( red, 50 ); }
			&__logo { background-color: fade( red, 50 ); }

			&__menu { background-color: fade( black, 75 ); outline: 1px solid fade( orange, 100 ); }
			&__menuLinks { background-color: fade( orange, 15 ); }
			&__menuLink { outline: 1px solid fade( orange, 100 ); }
			*/
		}
	}
	.MainHeader { // vars
		//
	}
	.MainHeader { // layout
		position: relative;
		height: var(--app-header-height);
		overflow: hidden;
		z-index: 100;

		&__inner {}
		&__row {
			display: flex;
			flex-direction: rows;

			@media @mq[dt] { padding: 24px 33px; }
			@media @mq[md] { padding: 18px 16px; }
			@media @mq[sm] { padding: 16px 12px; }
		}

		&__langSwitcher {
			pointer-events: auto;
		}
		&__logo {
			pointer-events: auto;

			@media @mq[dt] { height: 50px; width: 140px; }
			@media @mq[md] { height: 40px; width: 105px; }
			@media @mq[sm] { height: 40px; width: 105px; }

			overflow: hidden;
			text-indent: 50vw;
		}
	}
	.MainHeader { // styling
		background-color: var(--color-content-background);
		&& { pointer-events: none; }

		&__logo {
			background-image: url('./../assets/images/tmo-logo.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}

		// different colors on different views
		&__logo {
			transition: all var(--app-view-transitionDuration) ease;
			pointer-events: auto;
		}
		.App--routeName-PeopleView & .menuToggleBtn,
		.App--routeName-EventView & .menuToggleBtn {
			color: var( --color-weiss );
		}
		.App--routeName-PeopleView &__logo,
		.App--routeName-EventView &__logo {
			filter: invert( 100% );
		}

		&__menu {
			background-color: var( --color-schwarz );
		}
		&__menuLinks {
			@media @mq[dt] { font-size: 40px; line-height: 1em; }
			@media @mq[md] { font-size: 40px; line-height: 1em; }
			@media @mq[sm] { font-size: 40px; line-height: 1em; }
		}
		&__menuLink {
			color: var( --color-weiss );
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
