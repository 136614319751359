<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<FlowText></FlowText>

	2023-01-23	init

-->

<template>
	<div class="FlowText contentModule" :class="elmClasses" :data-dev-name="$options.name + ' • ' + textIndent">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.elmInnerStyles'"
				:data="elmInnerStyles"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<div class="FlowText__inner" :class="elmInnerClasses" :style="elmInnerStyles">
			<h4 class="FlowText__rotatedHeadline"
				v-if="hasRotatedHeadline && _.get( headline, 'text' )">
				<span :style="'color:' + _.get( headline, 'swatch' )">
					<span ref="rotatedHeadlineWrapper" v-html="_.get( headline, 'text' )"></span>
				</span>
			</h4>
			<div class="FlowText__text" v-html="text"></div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'FlowText',
		components: {},
		mixins: [],
		props: {
			hasRotatedHeadline: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			headline: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
			textIndent: {
				type     : [String, Number],
				default  : 0,
				required : false,
			},
			text: {
				type     : [String],
				default  : "",
				required : false,
			},
		},
		data(){
			return {
				elmInnerStyles : {
					minHeight : 'initial',
				},
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')
				//classes.push( this.$options.name + '--hasRotatedHeadline')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this._.get( this.headline, 'position' ) )

				if( !this.hasRotatedHeadline ) classes.push( this.$options.name + '--textIndent-' + this.textIndent )

				/*
				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			onWindowResize( e, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onWindowResize()' )
					//console.log('key:', value)
					console.groupEnd()
				}

				this.elmInnerStyles.minHeight = 'initial'
				this.setMinHeight()
			},
			setMinHeight( doLog = false ){ // damit die gestüzten headlines immer ausreichend platz haben benötigt der container eine mindesthöhe
				const rotatedHeadlineWrapperEl = this.$refs.rotatedHeadlineWrapper

				//console.log('rotatedHeadlineWrapperEl:', rotatedHeadlineWrapperEl)

				if( rotatedHeadlineWrapperEl ){
					const width = rotatedHeadlineWrapperEl.offsetWidth

					if( doLog ) console.log('	width:', width)

					this.elmInnerStyles.minHeight = 'calc(' + width + 'px * 1.05 )'
				}
			},
		},
		created(){
			window.addEventListener('resize', this._.debounce( this.onWindowResize, 250 ))
		},
		mounted(){
			this.setMinHeight()
		},
		destroyed(){
			window.removeEventListener('resize', this.onWindowResize)
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.FlowText { // debug
		[showBorders3] & {
			&__inner { background-color: fade( red, 20 ); }
			&__rotatedHeadline { background-color: fade( red, 20 ); }
			&__rotatedHeadline span { background-color: fade( red, 20 ); }
			&__text { background-color: fade( red, 20 ); }
		}
	}
	.FlowText { // vars
	}
	.FlowText { // layout
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;

			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}

		&--hasNoRotatedHeadline&--textIndent-0 {
			@media @mq[dt] { .FlowText__text { grid-column: 1 / span 6; } }
			@media @mq[md] { .FlowText__text { grid-column: 1 / span 6; } }
			@media @mq[sm] { .FlowText__text { grid-column: 1 / span 6; } }
		}
		&--hasNoRotatedHeadline&--textIndent-2 {
			@media @mq[dt] { .FlowText__text { grid-column: 3 / span 6; } }
			@media @mq[md] { .FlowText__text { grid-column: 3 / span 6; } }
			@media @mq[sm] { .FlowText__text { grid-column: 2 / span 7; } }
		}
		&--hasNoRotatedHeadline&--textIndent-4 {
			@media @mq[dt] { .FlowText__text { grid-column: 5 / span 6; } }
			@media @mq[md] { .FlowText__text { grid-column: 5 / span 4; } }
			@media @mq[sm] { .FlowText__text { grid-column: 3 / span 6; } }
		}

		&--hasRotatedHeadline {
			.FlowText__rotatedHeadline { grid-area: a; }
			.FlowText__text { grid-area: b; }
		}
		&--headlinePosition-left {
			@media @mq[dt] { grid-template-areas: "a a b b b b b b b b b b"; }
			@media @mq[md] { grid-template-areas: "a a b b b b b b"; }
			@media @mq[sm] { grid-template-areas: "a b b b b b b b"; }
		}
		&--headlinePosition-right {
			@media @mq[dt] { grid-template-areas: "b b b b b b b b b b a a"; }
			@media @mq[md] { grid-template-areas: "b b b b b b a a"; }
			@media @mq[sm] { grid-template-areas: "b b b b b b b a"; }
		}

		&__rotatedHeadline {
			overflow: hidden; // der gedrehte text fliesst manchmal nach unten aus dem wrapper raus.
		}
		&__rotatedHeadline > span {
			display: flex;
			justify-content: flex-end;
			padding: 0 0.25em;
		}
		&--headlinePosition-left &__rotatedHeadline > span {
			transform: rotate( -90deg ) translateX( -100% );
			transform-origin: top left;
		}
		&--headlinePosition-right &__rotatedHeadline > span {
			transform: rotate( -90deg ) translateY( -100% );
			transform-origin: top right;
		}
	}
	.FlowText { // styling
		&__rotatedHeadline {
			white-space: pre;

			@media @mq[dt] { font-size: 16px; line-height: 18px; }
			@media @mq[md] { font-size: 16px; line-height: 18px; }
			@media @mq[sm] { font-size: 12px; line-height: 14px; }
		}
		&__text {
			@media @mq[dt] { font-size: 24px; line-height: 28px; }
			@media @mq[md] { font-size: 24px; line-height: 28px; }
			@media @mq[sm] { font-size: 16px; line-height: 18px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
