<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<SingleImage></SingleImage>

	2019-06-08	init

-->

<template>
	<div class="SingleImage contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<div class="SingleImage__inner" ref="container">
			<template v-if="hasKenBurnsEffect">
				<ken-burns-carousel
					class="SingleImage__kenBurnsCarousel"
					:images="kenBurnsImagesStr"
					:slide-duration="_.random(12000, 17000)"
					:fade-duration="5000"
					:style="imgElStyles"
				></ken-burns-carousel>
			</template>
			<template v-else>
				<MhImage class="SingleImage__image"
					:imageObject="['md', 'sm', 'xs'].includes(this.$mq) && _.isObject(imageMd) ? imageMd : image"
					:mode="hasFixedHeight ? 'cover' : 'img'"
					:savePixel="50"
					:style="imgElStyles"
				></MhImage>
			</template>
			<!--
			<MhImage
				:imageObject="_image"
				:mode="mode"
				:style="imgElStyles"
			></MhImage>
			-->

			<div class="SingleImage__grid" v-if="bu">
				<div class="SingleImage__bu bu font font--system85-medium" v-html="bu"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MediaWrapper from '@/components/MediaWrapper.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'SingleImage',
		components: {
			MediaWrapper,
			MhImage,
		},
		mixins: [],
		props: {
			image: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			images: {
				type     : [Array, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			imageMd: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			imagesMd: {
				type     : [Array, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			hasFixedHeight: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			hasKenBurnsEffect: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			dtHeight: {
				type     : [Number, String],
				default  : 75,
				required : false,
			},
			mdHeight: {
				type     : [Number, String],
				default  : 75,
				required : false,
			},
			bu: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {
				resizeObserver: null,
				setSanitizedImages: null, // wird mounted() als fn gesetzt
				container: {
					el : undefined,
					width : undefined,
					height : undefined,
				},
				sanitizedImages: [],
			}
		},
		watch: {},
		computed: {
			_image(){
				const isBelowDt = ['md', 'sm', 'xs'].includes( this.$mq )
				return isBelowDt && this._.isObject( this.imageMd ) ? this.imageMd : this.image
			},
			kenBurnsImagesStr(){
				let doLog = false
				const urlsArr = []

				if( doLog ) console.group('kenBurnsImagesStr:')

				// collect the best urls
				this._.forEach( this.sanitizedImages, (sanitizedImage)=>{
					const url = sanitizedImage.bestSource.url
					const name = sanitizedImage.bestSource.name

					if( doLog ) console.log(name, ':', url)

					urlsArr.push( url )
				})
				if( doLog ) console.groupEnd()

				return urlsArr.join(' ')
			},
			imgElStyles(){
				let styles = {}

				// feste höhe: setze diese höhe als vh wert
				if( this.hasFixedHeight ){
					const height = ['xl', 'lg', 'dt'].includes( this.$mq ) ? parseFloat(this.dtHeight) : parseFloat(this.mdHeight)
					styles.height = height + 'vh'
				}
				// keine feste höhe aber ken-burns-effekt: die höhe des containers wird vom seitenverhältnis des ersten bildes bestimmt
				else if(this.hasKenBurnsEffect){
					const firstImage = ['md', 'sm', 'xs'].includes( this.$mq ) && this.imagesMd.length ? this.imagesMd[0] : this.images[0]
					const paddingBottom = (this._.get(firstImage, 'height') / this._.get(firstImage, 'width'))*100
					console.log('firstImage:', firstImage, paddingBottom)
					styles.paddingBottom = paddingBottom + '%'
				}

				return styles
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			onContainerResize( e ){
				const rect = e[0].contentRect
				//console.log( e, e[0].contentRect )
				requestAnimationFrame( async () => {
					//console.log( rect.width, rect.height )
					this.container.width = rect.width
					this.container.height = rect.height
					this.setSanitizedImages()
				})
			},
			getSanitizedImageObj( imagePostObj ){
				const newSourcesArr = []

				if( imagePostObj ){
					const sizes = imagePostObj.sizes

					for( let elem in sizes ){
						let key = elem
						let value = sizes[elem]

						// detect if key is a size name, e.g. "thumbnail"
						if( sizes[key+'-width'] ){
							newSourcesArr.push({
								name : key,
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							})
						}
					}
				}

				return {
					id : imagePostObj.id,
					bestSource : '',
					sources : newSourcesArr,
				}
			},
			getBestImageSource( imageSources, doLog = false ){
				const width = this.container.width
				const height = this.container.height
				const matchingSources = this._.filter( imageSources, (source)=>{
					return source.width >= width && source.height >= height
				})
				const bestImageSource = matchingSources.length > 0 ? matchingSources[0] : this._.find( imageSources, { name : 'full'} )

				if( doLog ){
					console.groupCollapsed('getBestImageSource()', imageSources)
					console.log('width:', width)
					console.log('height:', height)
					console.log('imageSources:', imageSources)
					console.log('matchingSources:', matchingSources)
					console.log('bestImageSource:', bestImageSource)
					console.log('test:', this._.find( imageSources, { name : 'full'} ))
					console.groupEnd()
				}

				return bestImageSource
			},
		},
		created(){},
		mounted(){
			// attach ResizeObserver to the container
			this.container.el = this.$refs.container
			this.resizeObserver = new ResizeObserver( this.onContainerResize )
			this.resizeObserver.observe( this.container.el )

			// after each container resize:
			// fill sanitizedImages array with image-objects.
			// each image-object includes the best source url
			// for current container dimensions
			this.setSanitizedImages = this._.throttle( ()=>{
				const sanitizedImages = []
				const images = ['md', 'sm', 'xs'].includes(this.$mq) ? this.imagesMd : this.images

				this._.forEach( images, ( imageObj )=>{
					const sanitizedImageObj = this.getSanitizedImageObj( imageObj )
					const bestImageSource = this.getBestImageSource( sanitizedImageObj.sources )

					sanitizedImageObj.bestSource = bestImageSource
					sanitizedImages.push( sanitizedImageObj )
				})

				this.sanitizedImages = sanitizedImages
			}, 1000 )
		},
		beforeDestroy(){
			// clean up the observer
			this.resizeObserver.disconnect()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.SingleImage { // debug
		[showBorders2] & {}
	}
	.SingleImage { // vars
	}
	.SingleImage { // layout
		&__grid {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;

			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}
		&__bu {
			@media @mq[dt] { grid-column: span 6; }
			@media @mq[md] { grid-column: 2 / span 6; }
			@media @mq[sm] { grid-column: 2 / span 6; }
		}

		&:first-child { // wenn als erstes contentModule soll es in den Header ragen
			margin-top: calc( var(--app-header-height) * -1 );
			//height: 75vh;
			//overflow: hidden;
		}

		&__kenBurnsCarousel {
			display: block;
		}
	}
	.SingleImage { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
