<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhPre__boolean></MhPre__boolean>

	2022-12-21	init

-->

<template>
	<div class="MhPre__boolean" :class=" data ? 'MhPre__boolean--isTrue' : 'MhPre__boolean--isFalse'">
		<span v-if="data === true">true</span>
		<span v-if="data === false">false</span>
	</div>
</template>

<script>
	export default {
		name: 'MhPre__boolean',
		components: {},
		mixins: [],
		props: {
			data: {
				type     : [Boolean],
				default  : undefined,
				required : false,
			},
			nestedLevel: {
				type     : [Number],
				default  : 0,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MhPreBoolean { // debug
	}
	.MhPreBoolean { // vars
	}
	.MhPreBoolean { // layout
	}
	.MhPreBoolean { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
