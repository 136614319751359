/*

    register MhPre component globally
    explained here https://sebastiandedeyne.com/exposing-multiple-vue-components-as-a-plugin/

    in your main.js:
    ---------------

    import Vue from 'vue'
    import MhPre from './MhPre.plugin.js'

    Vue.use( MhPre )

    new Vue({
        render: h => h(App)
    }).$mount('#app')

 */

import MhPre from './MhPre.vue'
import MhPre__boolean from './MhPre__boolean.vue'
import MhPre__null from './MhPre__null.vue'
import MhPre__string from './MhPre__string.vue'
import MhPre__number from './MhPre__number.vue'
import MhPre__undefined from './MhPre__undefined.vue'
import MhPre__collection from './MhPre__collection.vue'

export default {
    install (Vue) {
        Vue.component('MhPre', MhPre),
        Vue.component('MhPre__boolean', MhPre__boolean),
        Vue.component('MhPre__null', MhPre__null),
        Vue.component('MhPre__string', MhPre__string),
        Vue.component('MhPre__number', MhPre__number),
        Vue.component('MhPre__undefined', MhPre__undefined)
        Vue.component('MhPre__collection', MhPre__collection)
    }
}

export { MhPre }