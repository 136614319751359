<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<EventDetail></EventDetail>

	2023-01-30	init

-->

<template>
	<article class="EventDetail contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<!--
			<strong>{{$options.name}}</strong><br/>

			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		-->

		<div class="EventDetail__inner" :class="elmInnerClasses">
			<div class="EventDetail__grid">
				<MhImage class="EventDetail__image"
					:imageObject="image"
					:mode="'image'"
				></MhImage>

				<Spacer class="EventDetail__afterImageSpacer"></Spacer>

				<div class="EventDetail__hardfactsWrapper">
					<h2 class="EventDetail__title font font--grow-bd" v-html="title"></h2>
					<div class="EventDetail__dateTime font font--system85-medium" v-html="dateTime"></div>
					<div class="EventDetail__location font font--system85-medium" v-html="location"></div>
				</div>

				<div class="EventDetail__text font font--system85-medium" v-html="text"></div>
			</div>
		</div>
	</article>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import Spacer from '@/components/contentModules/Spacer.vue'

	export default {
		name: 'EventDetail',
		components: {
			MhImage,
			Spacer,
		},
		mixins: [],
		props: {
			image: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
			dateTime: {
				type     : [String],
				default  : '',
				required : false,
			},
			location: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.EventDetail { // debug
		[showBorders3] & {
			&__grid { background-color: fade( red, 25 ); }
			&__grid > * { background-color: fade( red, 25 ); }
		}
	}
	.EventDetail { // vars
		//
	}
	.EventDetail { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&__grid {
			display: grid;
			gap: var( --app-grid-gap );
			row-gap: 0; // wird mit Spacer gelöst
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
			grid-template-rows: auto;  /* key rule */

			@media @mq[dt] {
				grid-template-areas:
					"a a a a a a a a a a a a"
					"d d d d d d d d d d d d"
					"b b b b c c c c c . . ."
				;
			}
			@media @mq[md] {
				grid-template-areas:
					"a a a a a a a a"
					"d d d d d d d d"
					"b b b c c c c c"
				;
			}
			@media @mq[sm] {
				grid-template-areas:
					"a a a a a a a a"
					"d d d d d d d d"
					"b b b b b b b b"
					"c c c c c c c c"
				;
			}
		}

		&__image { grid-area: a; }
		&__hardfactsWrapper { grid-area: b; }
		&__text { grid-area: c; }
		&__afterImageSpacer { grid-area: d; }

		&__image {}
		&__hardfactsWrapper {}
		&__dateTime { padding-top: 0.75em; }
		&__location { padding-top: 1em; }
		&__text { @media @mq[sm] { padding-top: 1em; } }

		/*
		&__title { grid-area: b; }
		&__dateTime { grid-area: c; }
		&__location { grid-area: d; }
		&__text { grid-area: e; }
		&__expander { grid-area: f; }
		&__expander { height: 100%; }
		*/
	}
	.EventDetail { // styling
		color: var( --color-weiss );
		a { color: inherit; text-decoration: underline; }

		&__title {
			@media @mq[dt] { font-size: 50px; line-height: 50px; }
			@media @mq[md] { font-size: 32px; line-height: 32px; }
			@media @mq[sm] { font-size: 32px; line-height: 32px; }
		}
		&__dateTime, &__location {
			@media @mq[dt] { font-size: 16px; line-height: 18px; }
			@media @mq[md] { font-size: 16px; line-height: 18px; }
			@media @mq[sm] { font-size: 16px; line-height: 18px; }
		}
		&__text {
			@media @mq[dt] { font-size: 24px; line-height: 28px; }
			@media @mq[md] { font-size: 24px; line-height: 28px; }
			@media @mq[sm] { font-size: 22px; line-height: 27px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
