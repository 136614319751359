<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Headline></Headline>

	2023-01-23	init

-->

<template>
	<div class="Headline contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<div class="Headline__inner" :class="elmInnerClasses" :style="elmInnerStyles">
			<div :is="is"><span v-html="text"></span></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'Headline',
		components: {},
		mixins: [],
		props: {
			swatch: {
				type     : [String],
				default  : '',
				required : false,
			},
			type: {
				type     : [String],
				default  : 'h2',
				required : false,
			},
			align: {
				type     : [String],
				default  : 'center',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			is(){
				return this.type
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--' + this.type )

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.is === 'h1' ) classes.push( 'font font--grow-d')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-d')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium')

				classes.push( 'text text--align-' + this.align )

				return classes
			},
			elmInnerStyles(){
				let styles = {}

				styles.color = this.swatch

				return styles
			}
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.Headline { // debug
		[showBorders2] & {}
	}
	.Headline { // vars
	}
	.Headline { // layout
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			//max-width: var(--app-content-maxWidth);
			max-width: 1500px; // damit die überschrift auf sehr breiten monitoren nicht zu weit fliesst
			margin: 0 auto;
		}
	}
	.Headline { // styling
		&--h1 &__inner {
			@media @mq[dt] { font-size: 100px; line-height: 1em; }
			@media @mq[md] { font-size: 60px; line-height: 1em; }
			@media @mq[sm] { font-size: 40px; line-height: 1em; }
		}
		&--h2 &__inner {
			@media @mq[dt] { font-size: 50px; line-height: 1em; }
			@media @mq[md] { font-size: 34px; line-height: 1em; }
			@media @mq[sm] { font-size: 24px; line-height: 1em; }
		}
		&--h3 &__inner {
			@media @mq[dt] { font-size: 24px; line-height: 28px; }
			@media @mq[md] { font-size: 24px; line-height: 28px; }
			@media @mq[sm] { font-size: 16px; line-height: 18px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
