<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MainNav></MainNav>

	2023-02-09	init

-->

<template>
	<nav class="MainNav" :class="elmClasses" @transitionend="onAfterSlide" v-click-outside="onClickOutside">
		<div class="MainNav__header">
			<button class="menuToggleBtn menuToggleBtn--isActive"
				@click="onClickClose">
				<span class="menuToggleBtnLine"></span>
				<span class="menuToggleBtnLine"></span>
				<span class="menuToggleBtnLine"></span>
			</button>
			<LangSwitcher v-if="_.get( app, 'acfOptions.hasVisibleLangSwitcher' )" ></LangSwitcher>
			<div class="expander"></div>
			<a class="MainNav__logo" :href="homeUrl" @click="onClickClose">Home</a>
		</div>
		<div class="MainNav__body">
			<div class="MainNav__menuLinks font font--grow-bd">
				<a class="MainNav__menuLink"
					v-for="(item, itemIndex) in data" :key="itemIndex"
					:href="item.link.url"
					@click="onClickClose">
					<span class="MainNav__menuLinkIndent" v-for="(space) in itemIndex" :key="'s' + space"></span>
					<span v-html="item.link.title"></span>
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import LangSwitcher from '@/components/LangSwitcher.vue'
	import clickOutside  from '@/directives/clickOutside.directive.js'

	export default {
		name: 'MainNav',
		components: {
			LangSwitcher,
		},
		mixins: [],
		directives: { clickOutside },
		props: {
			data: {
				type: [Array, Boolean],
				default: ()=>{ return [] },
				required: false,
			},
			isOpen: {
				type: [Boolean],
				default: false,
				required: false,
			},
		},
		data(){
			return {
				isSlidedIn : false,
			}
		},
		watch: {},
		computed: {
			homeUrl(){
				const url = window.location.protocol + '//' + window.location.host + '/' + this.$i18n.locale + '/'

				return url
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isOpen ) classes.push( this.$options.name + '--isOpen')
				else classes.push( this.$options.name + '--isNotOpen')

				return classes
			},
		},
		methods: {
			windowResizeHandler( e ){
				this.$emit('closeMainNav')
			},
			onAfterSlide( e ){
				if( e.propertyName === 'transform' ){
					//console.log('onAfterSlide', this.isOpen, e)
					this.isSlidedIn = this.isOpen
				}
			},
			onClickOutside( e ){
				if( this.isSlidedIn ){
					//console.log('onClickOutside')
					this.$emit('closeMainNav')
				}
			},
			onClickClose( e ){
				if( this.isOpen ) this.$emit('closeMainNav')
			},
		},
		created(){
			window.addEventListener('resize', this.windowResizeHandler )
		},
		mounted(){},
		destroyed(){
			window.removeEventListener('resize', this.windowResizeHandler )
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MainNav { // debug
		[showBorders2] & {}
		//background-color: fade( green, 50 );

		[showBorders3] & {
			& * { background-color: fade( green, 25 ); outline: 1px solid fade( green, 25 ); }
		}
	}
	.MainNav { // vars
		//
	}
	.MainNav { // layout
		@media @mq[dt] { padding: 24px 33px; }
		@media @mq[md] { padding: 18px 16px; }
		@media @mq[sm] { padding: 16px 12px; width: 100%; }

		position: fixed;
		top: 0; left: 0px;
		min-height: 100vh;
		transition: all 0.25s ease;
		z-index: 100;
		display: flex;
		flex-direction: column;

		&--isNotOpen { transform: translateX( -100% ); pointer-events: none; }
		&--isOpen { transform: translateX( 0% ); pointer-events: auto; }

		&__header {
			display: flex;
			justify-content: space-between;
			flex-grow: 0;
			flex-shrink: 0;
		}
		&__body {
			//padding-top: 0.5em;
			display: flex;
			flex-direction: column;
			//justify-content: center;
			flex-grow: 1;
			padding-bottom: 1.5em;

			@media @mq[md] { justify-content: center; padding-top: 0em; }
			//@media @mq[sm] { justify-content: center; padding-top: 0em; }
			@media @mq[dt] { justify-content: flex-start; padding-top: 0.5em; }
		}

		&__logo {
			@media @mq[dt] { display: none; height: 50px; width: 140px; }
			@media @mq[md] { display: none; height: 40px; width: 105px; }
			@media @mq[sm] { display: block; height: 40px; width: 105px; }
		}

		&__menuLinks {
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}
		&__menuLink {
			display: flex;
			flex-grow: 1;
			span { display: block; }

			&Indent {
				//background-color: fade( red, 25 );

				width: 1em;
			}
		}
	}
	.MainNav { // styling
		@media @mq[dt] { font-size: 40px; line-height: 1em; }
		@media @mq[md] { font-size: 40px; line-height: 1em; }
		@media @mq[sm] { font-size: 40px; line-height: 1em; }

		background-color: var( --color-schwarz );

		&__logo {
			background-image: url('./../assets/images/tmo-logo.svg');
			background-repeat: no-repeat;
			background-size: contain;
			filter: invert( 100% );

			overflow: hidden;
			text-indent: 50vw;
		}

		&__menuLink {
			color: var( --color-weiss );
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
