<template>
	<div class="App handleTextHierachieOutlining font font--system85-medium font--sizeDefault text text--align-left" :class="elmClasses">
		<MhStickyHeader
			position="sticky"
			:hideBelow="18">
			<MainHeader
				@openMainNav="mainNavIsOpen = true"
			></MainHeader>
		</MhStickyHeader>
		<MainNav
			:data="mainNavItems"
			:isOpen="mainNavIsOpen"
			@closeMainNav="mainNavIsOpen = false"
		></MainNav>

		<!--
		<MhPre :name="'acfOptions'"
			:data="acfOptions"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="'mainNavItems'"
			:data="mainNavItems"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<pre>mainNavIsOpen: {{mainNavIsOpen}}</pre>
		<MhPre :name="$options.name + '.footer'"
			:data="footer"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		-->

		<MhRouterViewWrapper
			:viewKey="$route.path"
			:contentTransitionDuration="350"
			:spinnerTransitionDuration="350"
			:spinnerColor="'currentColor'"
			:spinnerStrokeWidth="2"
		></MhRouterViewWrapper>

		<MhDevGrid
			:doLog="false"
			:showOnHosts="['localhost', 'tmo.local', 'dev.tmo.wine']"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
			:columns="'var(--app-grid-columns)'"
			:gap="'var(--app-grid-gap)'"
			:maxWidth="'var(--app-content-maxWidth)'"
			:hPadding="'var(--app-content-hPadding)'"
			:color="'cyan'"
			:opacity="0.35"
			:toggleKey="'g'"
			:forceVisibleOnMount="true"
		></MhDevGrid>
		<MhDelegateLinks
			:doLog="false"
		></MhDelegateLinks>
		<MhTagsOutlining
			:doLog="false"
			:color="'rgba( 255, 0, 175, 0.5 )'"
			:selectors="[
				'[showborders4] .view'
			]"
		></MhTagsOutlining>
		<MhHeadInfos
			:doLog="false"
			:showDebugPanel="false"
			:ignoreViews="[
				'DevView',
			]"
		></MhHeadInfos>
		<MhDevInfos
			:doLog="false"
			:showOnHosts="['localhost', 'tmo.local', 'dev.tmo.wine']"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
		></MhDevInfos>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhPreviewLinkHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhPreviewLinkHandler/v1/MhPreviewLinkHandler.js'
	import MhDevInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue'
	import MhHeadInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhHeadInfos/v1/MhHeadInfos.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhRouterViewWrapper from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v5/MhRouterViewWrapper.vue'
	import MhDevGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevGrid/v6/MhDevGrid.vue'
	import MhStickyHeader from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhStickyHeader/v3/MhStickyHeader.vue'
	import MhTagsOutlining from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhTagsOutlining/v1/MhTagsOutlining.vue'

	import MainHeader from '@/components/MainHeader.vue'
	import MainNav from '@/components/MainNav.vue'

	export default {
		name: 'App',
		components: {
			MhHeadInfos,
			MhRouterViewWrapper,
			MhDevInfos,
			MhDelegateLinks,
			MhDevGrid,
			MhStickyHeader,
			MhTagsOutlining,
			MainHeader,
			MainNav,
		},
		directives: {},
		mixins: [
			restHandler,
			MhPreviewLinkHandler
		],
		data() {
			return {
				isReady: false,
				acfOptions: null,
				currentUser: null,
				showDebugVars: {
					contentModules : false,
				},
				showContentModuleDebugVars: false,
				header: { // wird via fetchAll gesetzt
					de : {},
					en : {},
				},
				footer: { // wird via fetchAll gesetzt
					de : {},
					en : {},
				},
				mainNavIsOpen : false,
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--routeName-' + this._.get( this.$route, 'name' ) )

				return classes
			},
			mainNavItems(){
				const lang = this.$i18n.locale

				return this._.get( this.acfOptions, lang + '.mainNavItems', [] )
			},
			footerData(){
				const lang = this.$i18n.locale
				const footerMenuItems = [
					this._.get( this.acfOptions, lang + '.footerMenuItems--column-0', {} ),
					this._.get( this.acfOptions, lang + '.footerMenuItems--column-1', {} ),
				]

				return this._.compact( footerMenuItems )
			},
		},
		methods: {
			setCssVars(){
				const root                = document.documentElement
				      root.style.overflow = 'auto'
				      root.style.height   = '101vh'
				const scrollbarWidth      = window.innerWidth - root.offsetWidth + 'px'
				      root.style.overflow = ''
				      root.style.height   = ''

				root.style.setProperty('--app-scrollbar-width', scrollbarWidth)
			},
			getLinkWithoutHostname( url ){
				let theReturn = ''

				try {
					let _url = new URL( url )
					theReturn = _url.pathname
				}
				catch(e) {
					//
				}

				return theReturn
			},
			fetchAll( finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch acfOptions
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						this.acfOptions = response.data.result

						runCallback()
					},
				})
				this.restHandler__fetch({
					action : 'GET',
					route : '/json/userInfos',
					params : {},
					callback : (response) => {
						this.currentUser = response.data

						runCallback()
					},
				})
				/*
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						//postIds : [114,165,166,105,164],
						postType : 'option_post',
					},
					callback : (response) => {
						const result = this._.get( response, 'data.result', [] )
						const footerPostDe = this._.find( result, { slug : 'footer-de' } )
						const footerPostEn = this._.find( result, { slug : 'footer-en' } )

						const headerPostDe = this._.find( result, { slug : 'header-de' } )
						const headerPostEn = this._.find( result, { slug : 'header-en' } )

						this.footer.de = {
							columns : this._.compact([
								this._.get( footerPostDe, 'acf.column_1'),
								this._.get( footerPostDe, 'acf.column_2'),
							])
						}
						this.footer.en = {
							columns : this._.compact([
								this._.get( footerPostEn, 'acf.column_1'),
								this._.get( footerPostEn, 'acf.column_2'),
							])
						}

						this.header.de = {
							menu : this._.get( headerPostDe, 'acf.links'),
						}
						this.header.en = {
							menu : this._.get( headerPostEn, 'acf.links'),
						}

						//console.log('result:', result)

						runCallback()
					},
				})
				*/
			},
		},
		mounted(){
			this.setCssVars()

			// set routeName as html[data-routeName] to handle color changes
			// depending on routeName
			const htmlEl = document.querySelector('html')
			if( htmlEl ) htmlEl.setAttribute('data-routeName', this.$route.name )

			this.$router.afterEach((to, from) => {
				if( htmlEl ) htmlEl.setAttribute('data-routeName', to.name )
			})

			this.fetchAll(()=>{
				this.isReady = true
			})

			/*
			this.$router.afterEach((to, from) => {
				console.group('this.$router.afterEach(to, from)')
				console.log('	from', from)
				console.log('	to  ', to)
				console.groupEnd()
			})

			this.$router.afterEach((to, from) => {
				console.group('this.$router.afterEach(to, from)')
				console.log('	from', from)
				console.log('	to  ', to)
				console.groupEnd()
			})
			*/

			// fetch example
			/*
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result

					// hier wird aus allen introImages eins zufällig ausgewählt
					this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )

					// um das layout handeln zu können sind die beiden
					// ersten menüpunkte, news + info, fest ins system integriert
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "Info",
						title__en: "Info",
						type: "toggleSecondRow",
						page: false
					})
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "News",
						title__en: "News",
						type: "homeView",
						page: false
					})

					this.$set(this.acfOptions, 'translations2', this.translations )
				},
			})
			*/

			// after route change: scroll App__body to top
			/*
				const AppBody = document.querySelector('.App__body')
				this.$router.afterEach( (to, from) => {
					AppBody.scrollTo(0, 0)
				})
			*/

			// EventBus example
			/*
				EventBus.$on('AppHeaderResized', elmHeight => {
					console.log( elmHeight )
				})
			*/
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/atoms.less";

	// vars
	:root {
		--app-scrollbar-width: 0px; // wird via js von setCssVars() gesetzt
		--app-view-transitionDuration: 500ms;
		--app-header-height: 100px;
		--app-content-hPadding: 2rem;
		--app-content-maxWidth: 100vw;
		--app-grid-columns: 12;
		--app-grid-gap: 16px;

		@media @mq[dt] {
			--app-header-height: 95px;
			--app-content-hPadding: 2rem;
			--app-grid-columns: 12;
			--app-grid-gap: 16px;
		}
		@media @mq[md] {
			--app-header-height: 70px;
			--app-content-hPadding: 16px;
			--app-grid-columns: 8;
			--app-grid-gap: 16px;
		}
		@media @mq[sm] {
			--app-header-height: 65px;
			--app-content-hPadding: 12px;
			--app-grid-columns: 8;
			--app-grid-gap: 9px;
		}

		--color-app-background: white;
		--color-content-background: transparent;
		--color-link: fade( blue, 90 );

		--color-weiss: #ffffff;
		--color-schwarz: #000000;
		--color-grun: #01AF47;
		--color-rot: #FF4A1A;
		--color-blau: #0400C5;
		--color-lila: #7000AC;
	}

	// dark-mode
	/*
	@media ( prefers-color-scheme: dark ){
		html { filter: invert(100%); }
		img { filter: invert(100%); }
	}
	*/

	// selectors
	html {
		color: var(--color-text);
		font-size: 16px;
		line-height: 1.2em;

		// verhindert fettung von hellem text auf dunklem untergrund
		// und sieht überhaupt natürlicher aus.
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
	}
	body { // different background-colors depending on route
		transition: background-color var(--app-view-transitionDuration) ease;
		[data-routeName="PeopleView"] & { --color-app-background: var(--color-rot); }
		[data-routeName="EventView"] & { --color-app-background: var(--color-blau); }
		background-color: var(--color-app-background);
	}
	strong, b {
		.font--bold;
	}
	label, button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5, h6 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		color: var(--color-link);
		text-decoration: none;
	}
	pre {
		@color: darken( white, 50 );

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;
		&[maxheight] { max-height: 300px; }

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }
		&:first-child { margin-top: 0em; }

		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 50);
		background-color: fade(@color, 5);
		overflow: auto;

		// label
		&[data-name]:after,
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 95);
			padding: 0.25em 0.5em;
			content: attr(data-name) attr(name);
			font-family: inherit;
		}
	}
	hr {
		margin: 0.5em 0;
		border: none;
		border-top: 1px solid;
		opacity: 0.25;
	}
	ul, ol {
		padding-left: 1rem;
	}
	sup {
		top: -0.25em;
	}

	.view {
		&__inner {
			background-color: var(--color-content-background);
			//padding: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
	}
	.contentModule {
		[showBorders2] & {
			.outline( magenta );
			.label( attr(data-dev-name), magenta, black );
		}
	}
	.handleTextLinks {
		a {
			color: inherit;
			text-decoration: underline;
		}
	}
	.bu {
		.font;
		.font--system85-medium;
		.handleTextLinks;

		padding-top: 0.5em;

		@media @mq[dt] { font-size: 16px; line-height: 18px; }
		@media @mq[md] { font-size: 16px; line-height: 18px; }
		@media @mq[sm] { font-size: 12px; line-height: 14px; }
	}
	.menuToggleBtn {
		//background-color: red;

		.reset--button;
		@stroke : 2px;
		position: relative;
		pointer-events: auto;
		flex-grow: 0;
		flex-shrink: 0;
		transition: all var(--app-view-transitionDuration) ease;

		@media @mq[dt] { height: 24px; width: 50px; }
		@media @mq[md] { height: 16px; width: 35px; }
		@media @mq[sm] { height: 16px; width: 35px; }

		&Line {
			position: absolute;
			display: block;
			height: @stroke;
			background-color: currentColor;
		}

		color: var( --color-schwarz );

		&--isInactive {

			.menuToggleBtnLine { width: 60%; }
			.menuToggleBtnLine:nth-child(1){ top: 0; left: 0; }
			.menuToggleBtnLine:nth-child(2){ top: calc( 50% - @stroke*0 ); left: 20%; }
			.menuToggleBtnLine:nth-child(3){ top: 100%; right: 0; }
		}
		&--isActive {
			color: var( --color-weiss );

			.menuToggleBtnLine { width: 107%; }
			.menuToggleBtnLine:nth-child(1){ top: 0; left: 0; }
			.menuToggleBtnLine:nth-child(2){ top: calc( 50% - @stroke*0 ); left: 20%; opacity: 0; }
			.menuToggleBtnLine:nth-child(3){ top: 100%; left: 0; }
			.menuToggleBtnLine:nth-child(1){ transform-origin: top left; transform: rotate( 25deg ); }
			.menuToggleBtnLine:nth-child(3){ transform-origin: bottom left; transform: rotate( -25deg ); }
		}
	}
	.expander {
		flex-grow: 1;
	}
	.minHeight100vhMinusMainHeader {
		//background-color: yellow;
		//min-height: calc( 100vh - var(--app-header-height) );

		// achtung, da es nun das feature gibt, dass ein 3-Spalter ShiftedMedia-Inhaltsmodul,
		// sofern es als erstes inhaltsmodul steht, in den Header hineinverschoben ist,
		// musste ich - var(--app-header-height) wieder enfernen.
		min-height: calc( 100vh );
	}

	.font {}
	.font--grow-d { font-family: 'ABCGrow-D'; }
	.font--grow-bd { font-family: 'ABCGrow-BD'; }
	.font--system85-medium { font-family: 'system85-medium'; }

	.text {}
	.text--align-left { text-align: left; }
	.text--align-center { text-align: center; }
	.text--align-right { text-align: right; }

	.App {
		//background-color: var(--color-app-background);
		min-height: 101vh;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
