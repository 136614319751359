<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MainFooter></MainFooter>

	2019-06-08	init

-->

<template>
	<div class="MainFooter" :class="elmClasses">
		<!--
			<MhPre :name="'linkColumns'"
				:data="linkColumns"
				:isStyled="true"
				:maxHeight="'650px'"
			></MhPre>
		-->
		<nav class="MainFooter__inner">
			<div class="MainFooter__column"
				v-for="(column, columnIndex) in linkColumns" :key="'ci'+columnIndex"
				:class="'MainFooter__column--' + columnIndex">
				<template v-for="(linkObj, linkIndex) in column">
					<template v-if="_.isObject( linkObj.link )">
						<a :key="'li'+linkIndex"
							:href="linkObj.link.url"
							:target="linkObj.link.target"
							v-html="linkObj.link.title">
						</a>
					</template>
					<template v-else>
						&nbsp;<br :key="'li'+linkIndex"/>
					</template>
				</template>
			</div>
			<div class="MainFooter__column MainFooter__column--last">
				<span></span>
				<span class="MainFooter__miniLogo"></span>
			</div>
		</nav>
		<!--
		<div class="MainFooter__bgExpander"></div>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MainFooter',
		components: {},
		mixins: [],
		props: {
			data: {
				type: [Array, Boolean],
				default: ()=>{ return [] },
				required: false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			linkColumns(){
				return this.data
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MainFooter { // debug
		[showBorders2] & {
			.outline( red );
			.label( 'MainFooter', red );
		}
	}
	.MainFooter { // vars
	}
	.MainFooter { // layout
		position: relative;
		padding: 0.75em 0;
		overflow: visible;

		&__inner {
			min-height: 100px;
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;

			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}
		&__column {
			display: flex;
			flex-direction: column;

			&--0, &--1 {
				@media @mq[dt] { grid-column: span 2; }
				@media @mq[md] { grid-column: span 2; }
				@media @mq[sm] { grid-column: span 3;}
			}
			&--last {
				align-items: flex-end;
				justify-content: space-between;

				@media @mq[dt] { grid-column: span 8;}
				@media @mq[md] { grid-column: span 4;}
				@media @mq[sm] { grid-column: span 2;}
			}
		}

		&__miniLogo {
			@media @mq[dt] { height: 45px; width: 33px; }
			@media @mq[md] { height: 35px; width: 26px; }
			@media @mq[sm] { height: 35px; width: 26px; }
		}

		/*
		&__bgExpander {
			position: absolute;
			bottom: -100px; left: 0; right: 0;
			background-color: red;
			height: 100px;
			//transform: translateY( 100% );
		}
		*/
	}
	.MainFooter { // styling
		color: white;
		background-color: var( --color-schwarz );

		a { color: inherit; }

		&__miniLogo {
			background-image: url('./../assets/images/tmo-logo--mini.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: bottom right;
		}

		@media @mq[dt] { font-size: 24px; line-height: 28px; }
		@media @mq[md] { font-size: 24px; line-height: 28px; }
		@media @mq[sm] { font-size: 16px; line-height: 22px; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
