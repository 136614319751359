<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<CardRow></CardRow>

	2023-01-25	init

-->

<template>
	<div class="CardRow contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.nextEvent'"
				:data="nextEvent"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
				v-if="isNextEventCard"
			></MhPre>
		</template>

		<div class="CardRow__inner"  :class="elmInnerClasses">

			<div class="CardRow__content">
				<template v-if="isNextEventCard">
					<Card
						:bgColor="swatch"
						:overTitle="overTitle"
						:image="_.get( nextEvent, 'acf.image' )"
						:headline="_.get( nextEvent, 'title.rendered' )"
						:subHeadline="getDateTimeStr( nextEvent )"
						:linkText="'Mehr erfahren'"
						:internalLink="_.get( nextEvent, 'link' )"
						v-if="nextEvent"
					></Card>
				</template>
				<template v-else>
					<Card
						:bgColor="swatch"
						:overTitle="overTitle"
						:image="_.get( card, 'image' )"
						:headline="_.get( card, 'headline' )"
						:subHeadline="_.get( card, 'subHeadline' )"
						:linkText="_.get( card, 'linkText' )"
						:internalLink="_.get( card, 'internalLink' )"
					></Card>
				</template>

				<!--
				<Card class="Events__eventCard" v-for="(post, postIndex) in posts" :key="postIndex"
					:bgColor="'var(--color-blau)'"
					:overTitle="''"
					:image="_.get( post, 'acf.image' )"
					:headline="_.get( post, 'title.rendered' )"
					:subHeadline="getDateTimeStr( post )"
					:linkText="'Mehr erfahren'"
					:internalLink="_.get( post, 'link' )"
				></Card>
				-->

			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import Card from '@/components/Card.vue'

	export default {
		name: 'CardRow',
		components: {
			Card,
		},
		mixins: [
			restHandler,
		],
		props: {
			overTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			indent: {
				type     : [String],
				default  : '',
				required : false,
			},
			swatch: {
				type     : [String],
				default  : '',
				required : false,
			},
			type: {
				type     : [String],
				default  : 'custom',
				required : false,
			},
			card: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
		},
		data(){
			return {
				nextEvent : undefined,
			}
		},
		watch: {},
		computed: {
			isNextEventCard(){
				return this.type === 'nextEvent'
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			getDateTimeStr( post ){
				// 15.7.2023 • 17<sup>30</sup>–22<sup>30</sup> h

				const date = this._.get( post, 'acf.startDate', '' )
				const startTime = this._.get( post, 'acf.startTime', '' )
				const endTime = this._.get( post, 'acf.endTime', '' )

				const year = date.substring( 0, 4 )
				const month = date.substring( 4, 6 )
				const day = date.substring( 6, 8 )

				const startTimeHours = startTime.substring( 0, 2 )
				const startTimeMinutes = startTime.substring( 2, 4 )
				const endTimeHours = startTime.substring( 0, 2 )
				const endTimeMinutes = startTime.substring( 0, 2 )

				let str = day + '.' + month + '.' + year +
					' &nbsp; ' + startTimeHours + '<sup>' + startTimeMinutes + '</sup>'

				if( endTime ){
					str += '–' + endTimeHours + '<sup>' + endTimeMinutes + '</sup>'
				}
				if( startTimeHours ){
					str += '&nbsp;h'
				}

				return str
			},
			fetchNextEventPost(){
				const postId = this.$route.query.postId
				const postSlug = this.$route.params.slug
				const fetchParams = {
					range : 'nowAndFuture',
					perPage : 1,
				}

				// fetch page
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/events',
					params : fetchParams,
					callback : (response) => {
						this.nextEvent = this._.get( response, 'data.result[0]' )

						//runCallback()
					},
				})
			},
		},
		created(){},
		mounted(){
			this.$el.style.setProperty('--CardRow-card-indent', parseInt( this.indent ) + 1)

			this.fetchNextEventPost()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.CardRow { // debug
		[showBorders2] & {}
	}
	.CardRow { // vars
		--CardRow-card-indent : 0;
	}
	.CardRow { // layout
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;

			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}

		&__content {
			@media @mq[dt] { grid-column: var( --CardRow-card-indent ) / span 10; }
			@media @mq[md] { grid-column: span 8; }
			@media @mq[sm] { grid-column: span 8; }
		}
	}
	.CardRow { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
