/**
 * Dieses Mixin soltte in App.vue eingebunden werden.
 * Es leitet Wordpress-Preview-Links zur Preview-Route (i.d.R. PageView) um.
 *
 * Damit das ganze funktioniert muss folgendes Script in die functions.php eingefügt werden:
 *
	function the_previewLink_fix ( $link, $post ) {
		return add_query_arg( [
			'postId' => $post -> ID,
			'postType' => $post -> post_type,
		], $link );
	}
	add_filter( 'preview_post_link', 'the_previewLink_fix', 10, 2 );
 */

import EventBus from '@/helper/EventBus.js'

export default {
	computed: {
		// http://localhost:3000/de/?page_id=53&preview=true&postId=53&postType=page
		isWordpressPreviewLinkUrl() {
			const query = this.$route.query
			const path = this.$route.path
			const isValid = ![
				!this._.includes(path, '/draft-preview/'),
				this._.has(query, 'postId'),
				this._.has(query, 'postType'),
				this._.get(query, 'preview', false),
			].includes(false)

			//console.log( 'query:', query )
			//console.log( 'isValid:', isValid )

			return isValid
		},
		// http://localhost:3000/de/draft-preview/?preview=true&postId=53&postType=page
		isVuePreviewLinkUrl() {
			const query = this.$route.query
			const path = this.$route.path
			const isValid = ![
				this._.includes(path, '/draft-preview/'),
				this._.has(query, 'postId'),
				this._.has(query, 'postType'),
				this._.get(query, 'preview', false),
			].includes(false)

			//console.log( 'query:', query )
			//console.log( 'isValid:', isValid )

			return isValid
		},
	},
	methods: {},
	mounted() {
		// redirect wordpress preview-link url to app preview route
		// from http://localhost:3000/de/?page_id=53&preview=true&postId=53&postType=page
		// to   http://localhost:3000/de/draft-preview/?preview=true&postId=53&postType=page
		if( this.isWordpressPreviewLinkUrl ){
			const path = this.$route.path
			const query = this.$route.query

			this.$router.replace(
				path + 'draft-preview/?' +
				'postId=' + query.postId + '&' +
				'postType=' + query.postType + '&' +
				'preview=true'
			)
		}
	},
}