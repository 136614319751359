<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhPre__string></MhPre__string>

	2022-12-21	init

-->

<template>
	<div class="MhPre__string">
		<span class="MhPre__stringBefore">"&VeryThinSpace;</span>
		<span class="MhPre__stringText">{{data}}</span>
		<span class="MhPre__stringAfter">&VeryThinSpace;"</span>
	</div>
</template>

<script>
	export default {
		name: 'MhPre__string',
		components: {},
		mixins: [],
		props: {
			data: {
				type     : [String],
				default  : undefined,
				required : false,
			},
			name: {
				type     : [String],
				default  : undefined,
				required : false,
			},
			nestedLevel: {
				type     : [Number],
				default  : 0,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MhPre__string { // debug
	}
	.MhPre__string { // vars
	}
	.MhPre__string { // layout
	}
	.MhPre__string { // styling
		a { color: fade( blue, 100 ); opacity: 0.65; text-decoration: none; }
		a:hover { opacity: 1; text-decoration: underline; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
