import { render, staticRenderFns } from "./FlowText.vue?vue&type=template&id=7cdfc21a&"
import script from "./FlowText.vue?vue&type=script&lang=js&"
export * from "./FlowText.vue?vue&type=script&lang=js&"
import style0 from "./FlowText.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports