<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<WinzerGrid></WinzerGrid>

	2023-01-30	init

-->

<template>
	<div class="WinzerGrid contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<!--
		<strong>{{$options.name}}</strong><br/>

		<MhPre :name="$options.name + '.$props'"
			:data="$props"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.$attrs'"
			:data="$attrs"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.posts'"
			:data="posts"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		-->

		<div class="WinzerGrid__inner" :class="elmInnerClasses">
			<div class="WinzerGrid__grid">
				<a class="WinzerGrid__gridItem" v-for="(post, postIndex) in posts" :key="postIndex"
					:href="getHref( post )"
					:download="type==='download'" :target="type==='download' ? '_blank' : ''">
					<MhAspectRatioWrapper class="WinzerGrid__imageWrapper"
						:aspectRatio="'100:140'">
						<MhImage class="WinzerGrid__image"
							:imageObject="_.get( post, 'acf.teaserImage' )"
							:mode="'cover'"
						></MhImage>
					</MhAspectRatioWrapper>

					<h4 class="WinzerGrid__title font font--system85-medium" v-html="_.get( post, 'title.rendered' )"></h4>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhAspectRatioWrapper from '@/components/vendor/MhAspectRatioWrapper/v1/MhAspectRatioWrapper.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'WinzerGrid',
		components: {
			MhAspectRatioWrapper,
			MhImage,
		},
		mixins: [
			restHandler,
		],
		props: {
			type: {
				type     : [String],
				default  : 'page',
				required : false,
			},
		},
		data(){
			return {
				posts : []
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			getHref( post ){
				//console.log('getHref():', post)

				return this.type === 'page' ? post.link : this._.get( post, 'acf.teaserImage.url' )
			},
			fetchPosts(){
				const fetchParams = {
					postType: 'people_post',
					perPage: -1,
				}

				// fetch page
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : fetchParams,
					callback : (response) => {
						this.posts = response.data.result

						//runCallback()
					},
				})
			},
		},
		created(){},
		mounted(){
			this.fetchPosts()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.WinzerGrid { // debug
		[showBorders3] & {
			&__gridItem { background-color: fade( red, 25 ); }
		}
	}
	.WinzerGrid { // vars
	}
	.WinzerGrid { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&__grid {
			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}
		&__gridItem {
			@media @mq[dt] { grid-column: span 2; }
			@media @mq[md] { grid-column: span 2; }
			@media @mq[sm] { grid-column: span 2; }
		}

		&__image {
			position: absolute;
			inset: 0;
		}
		&__title {
			padding: 0.5em 0;
		}
	}
	.WinzerGrid { // styling
		&__gridItem {
			color: inherit;
		}
		&__imageWrapper {
			background-color: fade( black, 10 );
		}
		&__title {
			@media @mq[dt] { font-size: 16px; line-height: 18px; }
			@media @mq[md] { font-size: 12px; line-height: 14px; }
			@media @mq[sm] { font-size: 12px; line-height: 14px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
