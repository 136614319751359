<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ImageAndText></ImageAndText>

	2023-01-28	init

-->

<template>
	<div class="ImageAndText contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<div class="ImageAndText__inner" :class="elmInnerClasses">
			<div class="ImageAndText__imageWrapper">
				<MhImage
					:imageObject="image"
				></MhImage>
				<div class="ImageAndText__bu bu" v-if="bu" v-html="bu"></div>
			</div>
			<div class="ImageAndText__textWrapper">
				<h3 class="ImageAndText__headline" v-html="headline" v-if="hasHeadline && headline"></h3>
				<div class="ImageAndText__text" v-html="text"></div>

			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'ImageAndText',
		components: {
			MhImage
		},
		mixins: [],
		props: {
			imagePosition: {
				type     : [String],
				default  : 'left',
				required : false,
			},
			hasHeadline: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			headline: {
				type     : [String],
				default  : '',
				required : false,
			},
			image: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			bu: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--imagePosition-' + this.imagePosition )

				if( this.hasHeadline ) classes.push( this.$options.name + '--hasHeadline' )
				else classes.push( this.$options.name + '--hasNoHeadline' )

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ImageAndText { // debug
		[showBorders2] & {}
	}
	.ImageAndText { // vars
	}
	.ImageAndText { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;

			display: grid;
			gap: var( --app-grid-gap );
			grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
		}
		&__imageWrapper { grid-area: a; }
		&__textWrapper { grid-area: b; }

		// spaltigkeit für bild und text ohne headline
		&--hasNoHeadline {
			.ImageAndText__inner {
				@media @mq[md] {
					grid-template-areas:
						". a a a a a a ."
						". . b b b b b b"
					;
				}
				@media @mq[sm] {
					grid-template-areas:
						"a a a a a a a a"
						"b b b b b b b b"
					;
				}
			}
		}
		&--hasNoHeadline&--imagePosition-left {
			.ImageAndText__inner {
				@media @mq[dt] { grid-template-areas: "a a a a . b b b b b b b"; }
			}
		}
		&--hasNoHeadline&--imagePosition-right {
			.ImageAndText__inner {
				@media @mq[dt] { grid-template-areas: "b b b b b b b . a a a a"; }
			}
		}

		// spaltigkeit für bild und text mit headline
		&--hasHeadline {
			.ImageAndText__inner {
				@media @mq[md] {
					grid-template-areas:
						". a a a a a a ."
						"b b b b b b b b"
					;
				}
				@media @mq[sm] {
					grid-template-areas:
						"a a a a a a a a"
						"b b b b b b b b"
					;
				}
			}
			// wenn eine headline vorhanden ist, wird der text eingerückt
			.ImageAndText__textWrapper {
				display: grid;
				gap: var( --app-grid-gap );

				@media @mq[dt] {
					grid-template-columns: repeat( 7, minmax(0, 1fr) );

					.ImageAndText__headline { grid-column: span 7; }
					.ImageAndText__text { grid-column: 3 / span 5; }
				}
				@media @mq[md] {
					grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );

					.ImageAndText__headline { grid-column: span var( --app-grid-columns ); }
					.ImageAndText__text { grid-column: 3 / span 6; }
				}
				@media @mq[sm] {
					grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );

					.ImageAndText__headline { grid-column: span var( --app-grid-columns ); }
					.ImageAndText__text { grid-column: 2 / span 7; }
				}
			}
		}
		&--hasHeadline&--imagePosition-left {
			.ImageAndText__inner {
				@media @mq[dt] { grid-template-areas: "a a a a . b b b b b b b"; }
			}
		}
		&--hasHeadline&--imagePosition-right {
			.ImageAndText__inner {
				@media @mq[dt] { grid-template-areas: "b b b b b b b . a a a a"; }
			}
		}
	}
	.ImageAndText { // styling
		&__textWrapper {
			@media @mq[dt] { font-size: 24px; line-height: 28px; }
			@media @mq[md] { font-size: 24px; line-height: 28px; }
			@media @mq[sm] { font-size: 16px; line-height: 18px; }
		}

		&__headline {
			color: var(--color-rot);
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
