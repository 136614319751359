<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2023-01-25	init

-->

<template>
	<div class="Dummy contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<strong>{{$options.name}}</strong><br/>

		<MhPre :name="$options.name + '.$props'"
			:data="$props"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.$attrs'"
			:data="$attrs"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>

		<div class="Dummy__inner" :class="elmInnerClasses">
			Dummy
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'

	export default {
		name: 'Dummy',
		components: {},
		mixins: [
			restHandler,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			someProp: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ){
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.Dummy { // debug
		[showBorders2] & {}
	}
	.Dummy { // vars
	}
	.Dummy { // layout
		// center row and note max-width
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
	}
	.Dummy { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
