<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Spacer :type="'l'"></Spacer>

	2023-01-25	init

-->

<template>
	<div class="Spacer contentModule" :class="elmClasses" :data-dev-name="$options.name + ' • ' + type">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<div class="Spacer__inner"  :class="elmInnerClasses"></div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'Spacer',
		components: {},
		mixins: [],
		props: {
			type: {
				type     : [String],
				default  : 'l',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--type-' + this.type )

				return classes
			},
			elmInnerClasses(){
				let classes = []

				/*
				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--hasRotatedHeadline')
				else classes.push( this.$options.name + '--hasNoRotatedHeadline')

				if( this.hasRotatedHeadline ) classes.push( this.$options.name + '--headlinePosition-' + this.headlinePosition )

				if( this.is === 'h1' ) classes.push( 'font font--grow-d text text--align-center')
				else if( this.is === 'h2' ) classes.push( 'font font--grow-bd text text--align-center')
				else if( this.is === 'h3' ) classes.push( 'font font--system85-medium text text--align-left')
				*/

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.Spacer { // debug
		[showBorders2] & {}
	}
	.Spacer { // vars
	}
	.Spacer { // layout
		&__inner {
			padding-right: var(--app-content-hPadding);
			padding-left: var(--app-content-hPadding);
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&--type-l {
			@media @mq[dt] { height: 75px; }
			@media @mq[md] { height: 50px; }
			@media @mq[sm] { height: 35px; }
		}
		&--type-m {
			@media @mq[dt] { height: 40px; }
			@media @mq[md] { height: 30px; }
			@media @mq[sm] { height: 20px; }
		}
		&--type-s {
			@media @mq[dt] { height: 20px; }
			@media @mq[md] { height: 15px; }
			@media @mq[sm] { height: 10px; }
		}
	}
	.Spacer { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
