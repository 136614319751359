<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhContentModuleWrapper
			v-for="(contentModule, contentModuleIndex) in _.get( post, 'acf.contentModules' )"
			:key="contentModuleIndex"
			:type="contentModule.acf_fc_layout"
			v-bind="contentModule"
		></MhContentModuleWrapper>

	2023-01-25	init

-->

<template>
	<div :class="elmClasses" :is="_is" v-bind="$attrs" :data-dev-name="hasComponentFile ? componentName : 'MhContentModuleWrapper'">

		<div class="MhContentModuleWrapper__errorMsg">
			<strong>Error:</strong>
			Component-File "{{componentName}}.vue"
			missing for type "{{contentModuleType}}".
		</div>

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import basename from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/helper/basename.js'

	// auto import all component-files from folder
	const includedFiles = {}
	const includedKeys = []
	function importAll( r ){
		r.keys().forEach(( key ) => {
			const _key = basename( key, false )
			const _value = r( key ).default

			// exclude files that start with underscore
			if( _key[0] !== '_' ){
				includedFiles[ _key ] = _value
				includedKeys.push( _key )
			}
		})
	}
	importAll( require.context('@/components/contentModules/', false, /\.vue/) )

	//console.log('includedFiles:', includedFiles)
	//console.log('includedKeys:', includedKeys)

	export default {
		name: 'MhContentModuleWrapper',
		components: includedFiles,
		mixins: [],
		props: {
			contentModuleType: {
				type: [String],
				default: '',
				required: true,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			_is(){
				return this.hasComponentFile ? this.componentName : 'div'
			},
			hasComponentFile(){
				return includedKeys.includes( this.componentName )
			},
			componentName(){
				return this._.upperFirst( this.contentModuleType )
			},
			elmClasses(){
				let classes = []

				// es sieht besser in den dev-tools aus, wenn in der reihenfolge die
				// classes zuerst die der inkludierten componete kommen und danach
				// erst die classes des MhContentModuleWrapper.
				// deswegen steht die class nicht oben im template sondern wird erst hier gesetzt.
				classes.push( this.$options.name )

				if( this.hasComponentFile ) classes.push( this.$options.name + '--' + this.componentName )
				else classes.push( this.$options.name + '--isMissingComponentFile' )

				return classes
			},
		},
		methods: {
			// da die übergebenen attrs hier keine props haben, stehen die alle im dom
			// und sind verwirrend in den dev-tools sichtbar. deswegen werden sie einfach
			// wieder aus dem dom entfernt. die inkludierte componet hat sie ja schon als
			// props erhalten.
			removeUselessAttrFromEl(){
				const attrs = this.$attrs

				this._.forEach( attrs, (v,k)=>{
					//console.log('	', k)
					this.$el.removeAttribute( k )
				})
			},
		},
		created(){},
		mounted(){
			//console.log( this.$options.name, '• mounted()', this.$attrs )

			this.removeUselessAttrFromEl()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	.MhContentModuleWrapper { // debug
		[showBorders2] & {}
	}
	.MhContentModuleWrapper { // vars
		//
	}
	.MhContentModuleWrapper { // layout
		&__errorMsg {
			margin: 0.25em 0;
			padding: 0.5em;
		}
	}
	.MhContentModuleWrapper { // styling
		&__errorMsg {
			background-color: fade( red, 25 );
			border: 1px solid fade( red, 50 );
			border-radius: 0.25em;
		}
	}
</style>
