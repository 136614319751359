<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MediaWrapper></MediaWrapper>

	2019-06-08	init

-->

<template>
	<div class="MediaWrapper" :class="elmClasses" :data-name="$options.name + ' • ' + aspectRatio">
		<!--
		<MhPre :name="'images'"
			:data="images"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="'sanitizedImages'"
			:data="sanitizedImages"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		-->

		<!--
		<div class="kenBurnsDemo">
			<ken-burns-carousel
				class="kenBurnsCarousel"
				XXXimages="https://source.unsplash.com/Qh9Swf_8DyA https://source.unsplash.com/O453M2Liufs"
				:images="kenBurnsImagesStr"
			></ken-burns-carousel>
		</div>
		-->

		<div class="MediaWrapper__inner" ref="container">
			<MhAspectRatioWrapper :aspectRatio="aspectRatio">
				<template v-if="hasKenBurnsEffect">
					<ken-burns-carousel
						class="MediaWrapper__kenBurnsCarousel"
						:images="kenBurnsImagesStr"
						:slide-duration="_.random(12000, 17000)"
						:fade-duration="5000"
					></ken-burns-carousel>
				</template>
				<template v-else>
					<MhImage class="MediaWrapper__image"
						:imageObject="firstImage"
						:mode="'cover'"
						:savePixel="savePixel"
					></MhImage>
				</template>
			</MhAspectRatioWrapper>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhAspectRatioWrapper from '@/components/vendor/MhAspectRatioWrapper/v1/MhAspectRatioWrapper.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import 'ken-burns-carousel'

	export default {
		name: 'MediaWrapper',
		components: {
			MhAspectRatioWrapper,
			MhImage,
		},
		mixins: [],
		directives: {},
		props: {
			aspectRatio: {
				type: [String],
				default: '16:9',
				required: false,
			},
			hasKenBurnsEffect: {
				type: [Boolean],
				default: false,
				required: false,
			},
			images: {
				type: [Array, Boolean],
				default: ()=>{ return [] },
				required: false,
			},
		},
		data(){
			return {
				resizeObserver: null,
				setSanitizedImages: null, // wird mounted() als fn gesetzt
				container: {
					el : undefined,
					width : undefined,
					height : undefined,
				},
				sanitizedImages: [],
			}
		},
		watch: {},
		computed: {
			kenBurnsImagesStr(){
				let doLog = false
				const urlsArr = []

				if( doLog ) console.group('kenBurnsImagesStr:')

				// collect the best urls
				this._.forEach( this.sanitizedImages, (sanitizedImage)=>{
					const url = sanitizedImage.bestSource.url
					const name = sanitizedImage.bestSource.name

					if( doLog ) console.log(name, ':', url)

					urlsArr.push( url )
				})
				if( doLog ) console.groupEnd()

				return urlsArr.join(' ')
			},
			savePixel(){
				// workaround für unscharfe bilder in md, sm, xs
				//
				// da es in den mqs unter dt zu sehr extremen formaten (krass hoch) kommen kann,
				// hat die bilddateiwahl von MhImage unscharfe ergebnisse geliefert.
				// deswegen habe ich mit den savePixel rumgespielt und bei 300 einen
				// "scharfen" wert gefunden.

				return ['md', 'sm', 'xs'].includes( this.$mq ) ? 300 : 0
			},
			firstImage(){
				return this.images[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			onContainerResize( e ){
				const rect = e[0].contentRect
				//console.log( e, e[0].contentRect )
				requestAnimationFrame( async () => {
					//console.log( rect.width, rect.height )
					this.container.width = rect.width
					this.container.height = rect.height
					this.setSanitizedImages()
				})
			},
			getSanitizedImageObj( imagePostObj ){
				const newSourcesArr = []

				if( imagePostObj ){
					const sizes = imagePostObj.sizes

					for( let elem in sizes ){
						let key = elem
						let value = sizes[elem]

						// detect if key is a size name, e.g. "thumbnail"
						if( sizes[key+'-width'] ){
							newSourcesArr.push({
								name : key,
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							})
						}
					}
				}

				return {
					id : imagePostObj.id,
					bestSource : '',
					sources : newSourcesArr,
				}
			},
			getBestImageSource( imageSources, doLog = false ){
				const width = this.container.width
				const height = this.container.height
				const matchingSources = this._.filter( imageSources, (source)=>{
					return source.width >= width && source.height >= height
				})
				const bestImageSource = matchingSources.length > 0 ? matchingSources[0] : this._.find( imageSources, { name : 'full'} )

				if( doLog ){
					console.groupCollapsed('getBestImageSource()', imageSources)
					console.log('width:', width)
					console.log('height:', height)
					console.log('imageSources:', imageSources)
					console.log('matchingSources:', matchingSources)
					console.log('bestImageSource:', bestImageSource)
					console.log('test:', this._.find( imageSources, { name : 'full'} ))
					console.groupEnd()
				}

				return bestImageSource
			},
		},
		created(){},
		mounted(){
			// attach ResizeObserver to the container
			this.container.el = this.$refs.container
			this.resizeObserver = new ResizeObserver( this.onContainerResize )
			this.resizeObserver.observe( this.container.el )

			// after each container resize:
			// fill sanitizedImages array with image-objects.
			// each image-object includes the best source url
			// for current container dimensions
			this.setSanitizedImages = this._.throttle( ()=>{
				const sanitizedImages = []

				this._.forEach( this.images, ( imageObj )=>{
					const sanitizedImageObj = this.getSanitizedImageObj( imageObj )
					const bestImageSource = this.getBestImageSource( sanitizedImageObj.sources )

					sanitizedImageObj.bestSource = bestImageSource
					sanitizedImages.push( sanitizedImageObj )
				})

				this.sanitizedImages = sanitizedImages
			}, 1000 )
		},
		beforeDestroy(){
			// clean up the observer
			this.resizeObserver.disconnect()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MediaWrapper { // debug
		[showBorders3] & {
			.label( attr( data-name ), red );
			.outline( red );
		}
		.MhAspectRatioWrapper {
			//background-color: fade( red, 25 );
			//outline: 1px solid red;
		}
	}
	.MediaWrapper { // vars
	}
	.MediaWrapper { // layout
		position: relative;

		&__inner { position: relative; min-height: 3em; } // just to show that something is missing
		&__kenBurnsCarousel { position: absolute; inset: 0; }
		&__image { position: absolute; inset: 0; }
	}
	.MediaWrapper { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
