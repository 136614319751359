<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ShiftedMedia></ShiftedMedia>

	2023-01-25	init

-->

<template>
	<div class="ShiftedMedia contentModule" :class="elmClasses" :data-dev-name="$options.name">
		<template v-if="_.get( app, 'showDebugVars.contentModules' )">
			<MhPre :name="$options.name + '.$props'"
				:data="$props"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
			<MhPre :name="$options.name + '.$attrs'"
				:data="$attrs"
				:isStyled="true"
				:maxHeight="'650px'"
				:keyColumnWidth="'10em'"
			></MhPre>
		</template>

		<!--
		<MhPre :name="$options.name + '.$props'"
			:data="$props"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.medias'"
			:data="medias"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '._medias'"
			:data="_medias"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		<MhPre :name="$options.name + '.$attrs'"
			:data="$attrs"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'10em'"
		></MhPre>
		-->

		<!--
		<div class="kenBurnsDemo">
			<ken-burns-carousel
				class="kenBurnsCarousel"
				XXXimages="https://source.unsplash.com/Qh9Swf_8DyA https://source.unsplash.com/O453M2Liufs"
				:images="kenBurnsImagesStr"
			></ken-burns-carousel>
		</div>
		-->

		<div class="ShiftedMedia__inner">
			<div class="ShiftedMedia__content" :style="contentStyles">
				<div class="ShiftedMedia__column" :class="'ShiftedMedia__column--' + mediaIndex" v-for="(media, mediaIndex) in _medias" :key="mediaIndex">
					<!-- der 3er wird auf der Startseite verwendet und hat Links -->
					<template v-if="_countMedia === 3">
						<a class="ShiftedMedia__link" :href="medias[mediaIndex].internalUrl">
							<MediaWrapper class="ShiftedMedia__media"
								v-bind="media"
							></MediaWrapper>
							<span class="ShiftedMedia__linkText font font--system85-medium text text--align-center" v-html="medias[mediaIndex].linkText"></span>
						</a>
					</template>
					<!-- alle anderen haben keinen Link, nur der 2er kann eine bu haben -->
					<template v-else>
						<MediaWrapper class="ShiftedMedia__media"
							v-bind="media"
						></MediaWrapper>
						<!--
						<template v-if="_countMedia === 2 && isIndented && bu && mediaIndex === 0 && ['xl', 'lg', 'dt'].includes( $mq )">
							<div class="ShiftedMedia__bu bu" v-html="bu"></div>
						</template>
						-->
						<template v-if="_countMedia === 2 && isIndented && bu && mediaIndex === 0">
							<div class="ShiftedMedia__bu bu" v-html="bu"></div>
						</template>
					</template>
				</div>
			</div>
			<!--
			<template v-if="_countMedia === 2 && isIndented && bu && ['md', 'sm', 'xs'].includes( $mq )">
				<div class="ShiftedMedia__bu bu" v-html="bu"></div>
			</template>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MediaWrapper from '@/components/MediaWrapper.vue'
	//import 'ken-burns-carousel'

	export default {
		name: 'ShiftedMedia',
		components: {
			MediaWrapper,
		},
		mixins: [],
		props: {
			countMedia: {
				type     : [String, Number],
				default  : '',
				required : false,
			},
			isIndented: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			aspectRatio: {
				type     : [Object],
				default  : ()=>{ return {} },
				required : false,
			},
			medias: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
			hasKenBurnsEffect: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			bu: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			_countMedia(){
				return parseInt( this.countMedia )
			},
			_aspectRatio(){
				let matchingAspectRatio

				if( ['xl', 'lg', 'dt'].includes( this.$mq ) ) matchingAspectRatio = this.aspectRatio.desktop
				else if( ['md'].includes( this.$mq ) ) matchingAspectRatio = this.aspectRatio.tablet
				else if( ['sm', 'xs'].includes( this.$mq ) ) matchingAspectRatio = this.aspectRatio.phone

				return matchingAspectRatio
			},
			_medias(){
				const countMedia = this._countMedia
				let newMedias = []

				for( let index = 0; index < countMedia; index++ ){
					const oldMedia = this.medias[index]
					let newMedia = {
						images : this._.get( oldMedia, 'images' ),
						hasKenBurnsEffect : this.hasKenBurnsEffect,
						aspectRatio : this._aspectRatio,
					}

					if( countMedia === 2 ){
						//
					}
					if( countMedia === 3 ){
						//newMedia.linkText = this._.get( oldMedia, 'linkText' )
						//newMedia.internalUrl = this._.get( oldMedia, 'internalUrl' )
					}
					if( countMedia === 4 ){
						//
					}
					if( countMedia === 5 ){
						//
					}

					//console.log(index, 'newMedia:', newMedia)
					newMedias.push( newMedia )
				}

				return newMedias
			},
			/*
			kenBurnsImagesStr(){
				let str = 'https://source.unsplash.com/Qh9Swf_8DyA https://source.unsplash.com/O453M2Liufs'
				str = 'https://source.unsplash.com/Qh9Swf_8DyA'

				return str
			},
			*/
			app(){
				return this.$root.$children[0]
			},
			contentStyles(){
				let styles = {}

				if( this._countMedia === 2 && this.isIndented ){
					styles.gridTemplateColumns = 'repeat( var( --app-grid-columns ), minmax(0, 1fr) )'
				}else{
					styles.gridTemplateColumns = 'repeat( ' + this._countMedia + ', minmax(0, 1fr) )'
				}

				return styles
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--countMedia-' + this._countMedia )

				if( this.isIndented ) classes.push( this.$options.name + '--isIndented' )
				else classes.push( this.$options.name + '--isFullbleed' )

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.kenBurnsDemo {
		position: relative;
		padding-bottom: 15%;
		background-color: fade( red, 20);

		& > * {
			position: absolute;
			inset: 0;
		}
	}

	.ShiftedMedia { // debug
		[showBorders2] & {}
	}
	.ShiftedMedia { // vars
		//
	}
	.ShiftedMedia { // layout
		&__inner {}
		&__content {
			display: grid;
		}
		&__column {}

		&__link {}
		&__linkText {
			display: block;
			padding-top: 1em;
		}
		&__bu {}


		// sonderfall für eingerückte 2er,
		// diese werden nicht randabfallend quasi inline dargestellt
		&--countMedia-2&--isIndented {

			// zeile braucht das normale padding und max-width verhalten
			.ShiftedMedia__inner {
				padding-right: var(--app-content-hPadding);
				padding-left: var(--app-content-hPadding);
				max-width: var(--app-content-maxWidth);
				margin: 0 auto;
			}

			// zeile braucht das normale grid
			.ShiftedMedia__content {
				display: grid;
				gap: var( --app-grid-gap );
				//grid-template-columns: repeat( var( --app-grid-columns ), minmax(0, 1fr) );
			}

			// versatz
			.ShiftedMedia__column--0 { margin-top: 0em; }
			.ShiftedMedia__column--1 { margin-top: 3em; }

			// die bilder müssen leicht vergrößert werden um das grid-gap zu füllen
			.ShiftedMedia__column--0 .ShiftedMedia__media { width: calc( 100% + ( var( --app-grid-gap ) * 0.5 ) ); }
			.ShiftedMedia__column--1 .ShiftedMedia__media { width: calc( 100% + ( var( --app-grid-gap ) * 0.5 ) ); margin-left: calc( var( --app-grid-gap ) * -0.5 ) }

			// ausspannen der columns
			@media @mq[dt] {
				.ShiftedMedia__column--0 { grid-column: 3 / span 4; }
				.ShiftedMedia__column--1 { grid-column: span 4; }

				.ShiftedMedia__column--0 { grid-column: 2 / span 5; }
				.ShiftedMedia__column--1 { grid-column: span 5; }
			}
			@media @mq[md] {
				.ShiftedMedia__column--0 { grid-column: span 4; }
				.ShiftedMedia__column--1 { grid-column: span 4; }
			}
			@media @mq[sm] {
				.ShiftedMedia__column--0 { grid-column: span 4; }
				.ShiftedMedia__column--1 { grid-column: span 4; }
			}

		}

		// versatz der 2er
		&--countMedia-2&--isFullbleed {
			.ShiftedMedia__column--0 { margin-top: 0em; }
			.ShiftedMedia__column--1 { margin-top: 3em; }
		}
		// versatz der 3er
		&--countMedia-3 {
			&:first-child { // der dreier soll in den header ragen
				margin-top: calc( var(--app-header-height) * -1 );
			}

			.ShiftedMedia__column--0 { margin-top: calc( var(--app-header-height) ); }
			.ShiftedMedia__column--1 { margin-top: 0em; }
			.ShiftedMedia__column--2 { margin-top: calc( var(--app-header-height) ); }
		}
		// versatz der 4er
		&--countMedia-4 {
			.ShiftedMedia__column--0 { margin-top: 3em; }
			.ShiftedMedia__column--1 { margin-top: 0em; }
			.ShiftedMedia__column--2 { margin-top: 3em; }
			.ShiftedMedia__column--3 { margin-top: 0em; }
		}
		// versatz der 5er
		&--countMedia-5 {
			/*
			.ShiftedMedia__column--0 { margin-top: 2.5em; }
			.ShiftedMedia__column--1 { margin-top: 0em; }
			.ShiftedMedia__column--2 { margin-top: 2.5em; }
			.ShiftedMedia__column--3 { margin-top: 0em; }
			.ShiftedMedia__column--4 { margin-top: 3.5em; }
			*/

			.ShiftedMedia__column--0 { margin-top: 0em; }
			.ShiftedMedia__column--1 { margin-top: 3em; }
			.ShiftedMedia__column--2 { margin-top: 0em; }
			.ShiftedMedia__column--3 { margin-top: 3em; }
			.ShiftedMedia__column--4 { margin-top: 0em; }
		}
	}
	.ShiftedMedia { // styling
		&__link {
			color: inherit;

			@media @mq[dt] { font-size: 20px; line-height: 1em; }
			@media @mq[md] { font-size: 16px; line-height: 1em; }
			@media @mq[sm] { font-size: 16px; line-height: 1em; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
