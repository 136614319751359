<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhPre__collection></MhPre__collection>

	2022-12-21	init

-->

<template>
	<div class="MhPre__collection"
		 :data-nestedLevel="nestedLevel"
		 :class="
			'MhPre__collection--nestedLevel-' + nestedLevel + ' ' +
			'MhPre__collection--type-' + ( _.isArray( data ) ? 'array' : 'object' )
		 "
		 XXXv-if="_.size( data )">
		 <!--
		 <pre>{{data}}</pre>
		 <pre>{{getChildComponentName(data)}}</pre>
		 -->

		<div class="MhPre__collectionInfo" :class="'MhPre__collectionInfo--size-' + _.size( data )" v-if="nestedLevel > 0" @click="hasVisibleChilds = !hasVisibleChilds">
			<!--
			<span class="MhPre__collectionInfoLeft">
			</span>
			-->
			<a class="MhPre__foldBtn"
				:class="{
					'MhPre__foldBtn--isActive' : hasVisibleChilds,
					'MhPre__foldBtn--isDisabled' : !_.size( data ),
				}"
			></a>

			<span class="MhPre__info MhPre__info--brackets" v-html="bracketsStr"></span>
			<span class="MhPre__info MhPre__info--collectionSize" v-html="sizeStr"></span>
		</div>

		<div class="MhPre__folder" v-show-slide="nestedLevel == 0 || hasVisibleChilds" v-if="_.size( data )">
			<!--
			<pre>{{name}}</pre>
			<pre>{{data}}</pre>
			-->
			<div class="MhPre__collectionItems"
				 :class="
					'MhPre__collectionItems--nestedLevel-' + nestedLevel + ' ' +
					'MhPre__collectionItems--size-' + _.size( data )
				 "
				 v-if="_.size( data ) || 1==1">
				<div class="MhPre__collectionItem"
					:class="
						'MhPre__collectionItem--nestedLevel-' + nestedLevel + ' ' +
						'MhPre__collectionItem--type-' + ( _.isArray( data ) ? 'array' : 'object' )
					"
					v-for="(v, k) in data" :key="k">
					<div class="MhPre__collectionItemKey" :class="
						'MhPre__collectionItemKey--nestedLevel-' + nestedLevel + ' ' +
						'MhPre__collectionItemKey--type-' + ( _.isArray( data ) ? 'array' : 'object' )">
						<span>{{k}}</span>
					</div>
					<div class="MhPre__collectionItemValue" :class="[
							'MhPre__collectionItemValue--nestedLevel-' + nestedLevel,
							'MhPre__collectionItemValue--type-' + ( _.isArray( data ) ? 'array' : 'object' ),
							'MhPre__collectionItemValue--child-' + getChildComponentName( v ),
							'MhPre__collectionItemValue--isEmpty-' + (_.isEmpty( v ) ? 'true' : 'false' ),
						]">
						<div :is="'MhPre__' + getChildComponentName( v )"
							 :data="v"
							 :name="name"
							 :nestedLevel="nestedLevel+1"
							 :hasVisibleChilds="unfoldedCollections.includes( k + nestedLevel )"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MhPre__collection',
		components: {},
		mixins: [],
		props: {
			data: {
				type     : [Array, Object],
				default  : undefined,
				required : false,
			},
			name: {
				type     : [String],
				default  : undefined,
				required : false,
			},
			nestedLevel: {
				type     : [Number],
				default  : 0,
				required : false,
			},
		},
		data(){
			return {
				unfoldedCollections: [],
				hasVisibleChilds: false,
				test: 345,
				testStr: "Peter",
			}
		},
		watch: {},
		computed: {
			bracketsStr(){
				const data = this.data
				let str = ''

				// prepend class name if is instance
				//if( !['Array', 'Object'].includes( data.constructor.name ) ){
					str += data.constructor.name + ' '
				//}

				// array brackets
				if( this._.isArray( data ) ){
					const first = data[0]
					const firstValue = JSON.stringify( data[0] )

					str += '['

					if( this._.size( data) ){

						str += '<span style="font-family: monospace; opacity: 0.5; color: brown;">'
						str += '&nbsp;'
						str += this._.isString( firstValue ) ? firstValue.substring(0, 8) : ''

						/*
						if( firstValue.length < 12 ){
							str += firstValue.substring( 0, 12 )
						}
						if( this._.size( data ) > 1 ){
							str += ', … '
						}
						*/

						str += ' …'
						str += '&nbsp;'
						str += '</span>'
					}
					else str += ''

					str += ']'
				}
				// object brackets
				else{
					const firstKey = Object.keys( data )[0]
					const firstValue = JSON.stringify( data[firstKey] )

					str += '{&nbsp;'

					if( this._.size( data ) ){
						//str += ' … '
						str += '<span style="font-family: monospace; opacity: 0.5; color: brown;">'
						//str += '&nbsp;'
						str += firstKey
						str += ':&nbsp;'

						if( firstValue && firstValue.length < 15 ){
							str += firstValue.substring( 0, 15 )
							if( this._.size( data ) > 1 ) str += ',&nbsp;…&nbsp;'
						}
						else{
							str += ' … '
						}
						/*
						if( this._.size( data ) > 1 ){
							str += ', … '

						}
						*/
						//str += ', … '

						//str += '&nbsp;'
						str += '</span>'
					}
					else str += '&nbsp; '

					str += '&nbsp;}'
				}

				return str
			},
			sizeStr(){
				const data = this.data
				let str = ''

				str += this._.size( data ) + '&nbsp;'

				if( this._.isArray( data ) ){
					str += 'item'

					if( this._.size( data ) !== 1 ) str += 's'
				}
				else{
					str += 'propert'

					if( this._.size( data ) ) str += 'ies'
					else str += 'y'
				}


				/*
				{{ _.size( data ) }}
				<template v-if="_.isArray( data )">item<template v-if="_.size( data ) != 1">s</template></template>
				<template v-else>propert<template v-if="_.size( data ) != 1">ies</template><template v-else>y</template></template>
				*/
				return str
			},
		},
		methods: {
			getChildComponentName( value, doLog = false ){
				let type = ''

				if( this._.isBoolean( value ) ) type = 'boolean'
				else if( this._.isString( value ) ) type = 'string'
				else if( this._.isNumber( value ) ) type = 'number'
				else if( this._.isArray( value ) ) type = 'collection'
				else if( this._.isObject( value ) ) type = 'collection'
				else if( this._.isNull( value ) ) type = 'null'
				//else if( this._.isArray( value ) ) type = 'array'
				//else if( this._.isObject( value ) ) type = 'object'
				else if( this._.isUndefined( value ) ) type = 'undefined'
				else type = 'string'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• getChildComponentName( value )', type )
					console.log('value:', value)
					console.log('-----')
					console.log('type:', type)
					console.groupEnd()
				}

				return type
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MhPreArray { // debug
	}
	.MhPreArray { // vars
	}
	.MhPreArray { // layout
	}
	.MhPreArray { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
