import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use( Router )

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass: 'routerLink--active',
	linkExactActiveClass: 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			redirect: '/de/', // set root route language
		},
		{
			path: '/dev/',
			name: 'DevView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "DevView" */ '@/views/DevView.vue')
		},
		{
			path: '/:lang/',
			name: 'HomeView',
			pathToRegexpOptions: {strict: true},
			component: HomeView,
		},
		{
			path: '/:lang/people/:slug/',
			name: 'PeopleView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "PeopleView" */ '@/views/PeopleView.vue')
		},
		{
			path: '/:lang/events/:slug/',
			name: 'EventView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "EventView" */ '@/views/EventView.vue')
		},
		{
			path: '/:lang/:slug/',
			name: 'PageView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "PageView" */ '@/views/PageView.vue')
		},
		{
			path: "*",
			name: 'NotFoundView',
			component: () =>
				import( /* webpackChunkName: "NotFoundView" */ '@/views/NotFoundView.vue')
		},
	]
})
