<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhPre :name="'PropertiesForm.items'"
			:data="items"
			:isStyled="true"
			:maxHeight="'650px'"
			:keyColumnWidth="'7em'"
		></MhPre>

	2022-12-21	init

-->

<template>
	<div class="MhPre" :class="elmClasses" :style="elmStyles">

		<div class="MhPre__header">
			<div class="MhPre__dataName" v-html="name" v-if="name"></div>
			<div class="MhPre__dataType" v-html="getValueType( v )"></div>
			<div class="MhPre__styleSwitcher">
				<label class="MhPre__styleSwitcherLabel">
					<input class="MhPre__styleSwitcherBtn" type="checkbox" v-model="isRaw" />
					<!--
					Show&nbsp;Raw
					-->
				</label>
			</div>
		</div>

		<div class="MhPre__body MhPre__body--isStyled"
				v-if="isRaw === true ? false : isStyled"
				:is="'MhPre__' + getChildComponentName( v )"
				:data="v"
				:name="name"
				:nestedLevel="nestedLevel+1"
				:style="bodyStyles"
		></div>

		<div class="MhPre__body MhPre__body--isRaw" v-else>
			<div class="MhPre__data">{{data}}</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'MhPre',
		components: {},
		mixins: [],
		props: {
			name: {
				type     : [String],
				default  : '',
				required : false,
			},
			data: {
				type     : [Boolean, String, Number, Array, Object],
				default  : undefined,
				required : false,
			},
			maxHeight: {
				type     : [String],
				default  : 'auto',
				required : false,
			},
			keyColumnWidth : {
				type     : [String],
				default  : '',
				required : false,
			},
			isStyled: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			nestedLevel: {
				type     : [Number],
				default  : -1,
				required : false,
			},
		},
		data(){
			return {
				isRaw : false,
			}
		},
		watch: {
			keyColumnWidth: {
				handler: function( to, from ){
					this.setCssProperties()
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			v(){
				return this.data
			},
			showStyled(){
				//if( this.isStyled === true || this.isRaw === true ) return true
				//else return false

				return this.isRaw === true ? false : this.isStyled
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmStyles(){
				let styles = {}
				//styles.maxHeight = this.maxHeight
				return styles
			},
			bodyStyles(){
				let styles = {}
				styles.maxHeight = this.maxHeight
				return styles
			},
		},
		methods: {
			getValueType( value, doLog = false ){
				let type = ''

				if( this._.isBoolean( value ) ) type = 'Boolean'
				else if( this._.isString( value ) ) type = 'String'
				else if( this._.isNumber( value ) ) type = 'Number'
				//else if( this._.isArray( value ) ) type = 'collection'
				//else if( this._.isObject( value ) ) type = 'collection'
				else if( this._.isArray( value ) ) type = 'Array'
				else if( this._.isObject( value ) ) type = 'Object'
				else if( this._.isUndefined( value ) ) type = 'Undefined'

				//if( type && !['Array', 'Object'].includes( value.constructor.name ) ){
				if( this._.get( value, 'constructor.name' ) ){
					type = value.constructor.name //+ ' ' + '{}'
				}

				if( this._.isArray( value )  ){
					type = type + '&nbsp;' + '[]'
				}
				else if( this._.isObject( value ) ){
					type = type + '&nbsp;' + '{}'
				}

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• getValueType( value )', type )
					console.log('value:', value)
					console.log('-----')
					console.log('type:', type)
					console.groupEnd()
				}

				return type
			},
			getChildComponentName( value, doLog = false ){
				let type = ''

				if( this._.isBoolean( value ) ) type = 'boolean'
				else if( this._.isString( value ) ) type = 'string'
				else if( this._.isNumber( value ) ) type = 'number'
				else if( this._.isArray( value ) ) type = 'collection'
				else if( this._.isObject( value ) ) type = 'collection'
				else if( this._.isNull( value ) ) type = 'null'
				//else if( this._.isArray( value ) ) type = 'array'
				//else if( this._.isObject( value ) ) type = 'object'
				else if( this._.isUndefined( value ) ) type = 'undefined'
				else type = 'string'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• getChildComponentName( value )', type )
					console.log('value:', value)
					console.log('-----')
					console.log('type:', type)
					console.groupEnd()
				}

				return type
			},
			setCssProperties(){
				if( this.$el && this.keyColumnWidth ) this.$el.style.setProperty('--MhPre-key-width', this.keyColumnWidth )
			},
		},
		created(){},
		mounted(){
			this.setCssProperties()
			/*
			// Tests welche eigenschaften ich von einem object bekomme, wenn ich es walke
			console.group( this.$options.name, '• mounted()', this.name, this.data )
			let obj = this.data
			this._.forEach( obj, (v,k)=>{
				console.log( k, v )
			})
			console.log('--------')
			for( var prop in obj ){
				if( Object.prototype.hasOwnProperty.call( obj, prop ) ){
					console.log( prop, obj[prop] )
					// do stuff
				}
			}
			console.log('--------')
			for( const [key, value] of Object.entries( obj ) ){
				console.log(`${key}: ${value}`);
			}
			console.log('--------')
			const getters = Object.entries(Object.getOwnPropertyDescriptors(obj))
				.filter(([key, descriptor]) => typeof descriptor.get === 'function')
				.map(([key]) => key)

			console.log(getters)
			console.log('--------')
			function hasMethod (obj, name) {
				const desc = Object.getOwnPropertyDescriptor (obj, name);
				return !!desc && typeof desc.value === 'function';
			}
			function getInstanceMethodNames( obj, stop ){
				let array = [];
				let proto = Object.getPrototypeOf (obj);
				while (proto && proto !== stop) {
					Object.getOwnPropertyNames (proto)
					.forEach (name => {
						if (name !== 'constructor') {
							if (hasMethod (proto, name)) {
								array.push (name);
						}
						}
					});
					proto = Object.getPrototypeOf (proto);
				}
				return array;
			}
			console.log( getInstanceMethodNames( obj ) )
			console.log('--------')
			console.log( Object.getOwnPropertyDescriptors( obj ) )
			console.groupEnd()
			*/
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	// TODO: colors are a little bit messy

	.MhPre { // debug
		[showBorders2] & {
			XXX&__collection {
				&::before {
					position: absolute;
					top: 0; right: 0;
					content: attr( data-nestedLevel );
					background-color: yellow;
					padding: 0.3em;
					z-index: 1;
				}
			}
		}
	}
	.MhPre { // vars
		--MhPre-padding: 5px 8px 5px 7px;
		--MhPre-index-width: 2.2em;
		--MhPre-key-width: 7em; // kann auch via setCssProperties gesetzt werden

		--MhPre-font-monospace: Consolas, monospace;
		--MhPre-font-sans: 'Roboto', 'Helvetica Neue';
		--MhPre-font-size: 12px;
		--MhPre-line-height: 14px;

		--MhPre-fg: fade( black, 100 );
		--MhPre-fg-light: lighten( black, 35 );
		--MhPre-bg-darker: darken( white, 25 );
		--MhPre-bg: darken( white, 15 );
		--MhPre-bg-light: darken( white, 35 );
		--MhPre-bg-white: lighten( white, 80 );
		--MhPre-border: 1px solid lighten( black, 45 );
		--MhPre-border-light: 1px solid lighten( black, 75 );
	}
	.MhPre { // layout
		position: relative;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		//margin: 0.35em 0 0;
		&:not(:last-child){ margin: 0.5em 0; }
		&:first-child{ margin-top: 0; }

		&__name {
			position: absolute;
			top: 0; right: 0;
		}
		&__styledData,
		&__data {
			flex-grow: 1;
			overflow: auto;
		}
	}
	.MhPre { // styling
		border: 1px solid var(--color-border);
		background-color: var(--color-background);
		//border-radius: 0.25em;

		&__name {
			padding: 0.25em 0.5em 0.4em;

			font-family: sans-serif;
			font-size: 12px;
			line-height: 1em;

			background-color: var(--color-border);
			color: var(--color-label);
		}
		&__data {
			padding: 0.5em;

			font-family: monospace;
			white-space: pre;
			color: var(--color-border);
			tab-size: 4;
		}
	}

	.MhPre { // layout der types
		&__header { display: flex; }
		&__body { overflow: auto; }
		&__dataName { &:after { display: inline-flex; padding: 0 0.65em; content: "•"; } }
		//&__dataName { &:after { display: inline-flex; padding-left: 0.5em; content: "·"; } }

		// muss noch zwischen layout und styling getrennt werden
		&__foldBtn {
			margin-right: 1em;
			display: inline-flex;
			color: inherit;
			align-items: center;
			justify-content: center;
			width: var(--MhPre-index-width);
			flex-grow: 0;
			height: 1em;
			width: 1em;
			transition: all 0.25s ease;
			opacity: 0.4;
			overflow: hidden;

			&::before { content: "▸"; }
			&::before { content: "▶"; }

			&:hover { opacity: 0.95; }

			&--isActive {
				transform: rotate(90deg);
			}
			&--isDisabled {
				transform: rotate(90deg);
				pointer-events: none;
				opacity: 0.15;
			}
		}
		&__collectionInfo:not( &__collectionInfo--size-0 ):hover &__foldBtn { opacity: 0.95; }

		&__collection { position: relative; XXXoverflow: hidden; }
		&__collectionItem { display: flex; overflow: hidden; }
		&__collectionInfo { position: sticky; top: 0; white-space: nowrap; }
		&__collectionItemKey { flex-shrink: 0; display: flex; justify-content: space-between; }
		&__collectionItemKey--type-array { width: var(--MhPre-index-width); }
		&__collectionItemKey--type-object { width: var(--MhPre-key-width); }
		&__collectionItemValue--type-array { flex-grow: 1; width: calc( 100% - var(--MhPre-index-width) ); }
		&__collectionItemValue--type-object { flex-grow: 1; width: calc( 100% - var(--MhPre-key-width) ); }
	}
	.MhPre { // styling der types
		font-size: var(--MhPre-font-size);
		line-height: var(--MhPre-line-height);
		font-family: var(--MhPre-font-sans);
		color: var(--MhPre-fg);

		// TODO: später noch einsortieren
		&__styleSwitcher {
			//background-color: fade( green, 20 );

			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;
		}
		&__styleSwitcherLabel {
			//background-color: fade( green, 20 );

			padding: 0 2em;
			cursor: pointer;
			user-select: none;
			font-weight: normal;
		}
		&__styleSwitcherBtn {
			position: absolute;
			top: 0; right: 0; bottom: 0;
			width: 1em;
		}

		// cursor
		&__collectionInfo:not(&__collectionInfo--size-0) { cursor: pointer; }

		// padding
		&__header { padding: var(--MhPre-padding); }
		&__collectionInfo { padding: var(--MhPre-padding); }
		&__collectionItemKey {	padding: var(--MhPre-padding); }
		&__string {	padding: var(--MhPre-padding); }
		&__number {	padding: var(--MhPre-padding); }
		&__boolean { padding: var(--MhPre-padding); }
		&__null { padding: var(--MhPre-padding); }
		&__undefined { padding: var(--MhPre-padding); }
		&__emptyValue { padding: var(--MhPre-padding); }

		// TODO: das muss später noch einsortiert werden
		// und überhaupt gehört das ganze styling mal refaktored
		.MhPre__info {}
		.MhPre__info--brackets {
			margin-right: 1em;
			font-family: monospace;
		}
		.MhPre__info--collectionSize {
			//background-color: red;
			opacity: 0.35;
		}

		// inset for collections
		&__collectionItems:not( &__collectionItems--nestedLevel-0 ):not( &__collectionItems--size-0 ){
			margin: 10px;
		}

		// border
		& { border: var(--MhPre-border); }
		&__header + &__body:not(&__body:empty) { border-top: var(--MhPre-border); }
		&__collectionItems { border: var(--MhPre-border); }
		&__collectionItems--nestedLevel-0 { border-left: none; border-right: none; border-top: none; border-bottom: none; }
		&__collectionItem + &__collectionItem { border-top: var(--MhPre-border-light); }

		// inner shadow for collections (objects and arrays)
		&__collectionInfo {
			position: relative;

			&::before {
				position: absolute;
				bottom: 0px; left: 0; right: 0;
				height: 5px;
				background: orange !important;
				background: linear-gradient( fade(black, 25), fade(black, 0) ) !important;
				transform: translateY( 100% );
				content: "";
				z-index: 20;
			}
		}

		// bg
		&__body--isRaw { background: var(--MhPre-bg-white); }
		&__header { background: var(--MhPre-bg-darker);	}
		&__collectionItemKey { background: var(--MhPre-bg);	}
		&__emptyValue { background: var(--MhPre-bg-white); }
		&__folder { background: var(--MhPre-bg-white); background: darken( white, 5 );}
		&__collectionInfo,
		&__number,
		&__boolean,
		&__undefined,
		&__string {
			background: var(--MhPre-bg-white);
		}

		// fonts
		&__header { font-weight: 500; }
		&__dataName { white-space: pre; }
		&__dataType { font-family: var(--MhPre-font-monospace); }
		//&__collectionInfoLeft { font-family: var(--MhPre-font-monospace); }

		&__number { font-family: var(--MhPre-font-monospace); }
		&__null { font-style: italic; }
		&__boolean { font-family: var(--MhPre-font-monospace); }
		&__undefined { font-family: var(--MhPre-font-monospace); }
		&__emptyValue { font-family: var(--MhPre-font-monospace); }
		//&__collectionItemKey span { font-family: var(--MhPre-font-monospace); color: var(--MhPre-fg-light); }

		// opacity
		&__collectionInfoRight { transition: all 0.25s ease; opacity: 0.35; }
		&__collectionInfo &__info--collectionSize { transition: all 0.25s ease; }
		&__collectionInfo:not( &__collectionInfo--size-0 ):hover &__info--collectionSize { opacity: 0.95; }

		// colors
		&__number { color: #9933ff; }
		&__boolean--isTrue { color: green; }
		&__boolean--isFalse { color: red; }
		&__undefined { color: var( --MhPre-bg-dark ); }
		&__stringBefore { color: lighten( brown, 20 ); }
		&__stringText { color: darken( brown, 5 ); }
		&__stringAfter { color: lighten( brown, 20 ); }

		// special types styling
		//&__string { &::before{ content: '"'; padding-right: 0.1em; } &::after{ content: '"'; padding-left: 0.1em; } }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
