/**
 * Excludes basename from path
 *   basename('./test/index.js') > index.js
 *   basename('./test/index.js', false) > index
 *
 * @param path : string
 * @param withExtension : boolean
 * @returns string
 */

function basename( path, withExtension = true ){
	const filename = path.split('/').reverse()[0]
	const filenameWithoutExtension = filename.split('.')[0]
	return withExtension ? filename : filenameWithoutExtension
}

export default basename;