export default {
	de : {
		placeholders : {},
		nav : {},
		titles : {},
		buttons : {},
		messages : {
			noUpcomingEvents : `
				Im Moment stehen zwar keine Veranstaltungen in unserem Kalender, aber wir freuen uns natürlich jederzeit über Ihren Besuch in Winningen: Lernen Sie unseren Wein dort kennen, wo er entsteht.
			`
		}
	},
	en : {
		placeholders : {},
		nav : {},
		titles : {},
		buttons : {},
		messages : {
			noUpcomingEvents : `No upcoming events.`
		}
	},
}
